@import '../../../../../theme/color.scss';
@import '../../../../../theme/commons.scss';

.emojis {
  position: absolute;
  display: inline-block;
  width: 245px;
  padding: 8px 5px;
  border-radius: 5px;
  box-sizing: border-box;
  bottom: calc(100% - 12px);
  left: 16px;
  background: $app_white_absolute;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
  border: 1.5px solid $app_white_shade3;
  z-index: calc(#{$high_front} + 1);

  & > ul {
    position: relative;
    width: 100%;
    float: left;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;

    & > li {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      height: 26px;
      width: 26px;
      margin: 4px 4px;
      padding: 2px;
      margin-top: 6px;
      border-radius: 3px;
      cursor: pointer;
      background: transparent;

      &:hover {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.3) !important;
      }

      & > img {
        position: relative;
        display: inline-block;
        text-align: center;
        margin-top: 4px;
        height: 18px;
        width: 18px;
        object-fit: cover;
      }
    }
  }

  &_dark {
    background: $app_darkmode_background_header1;
    border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
    -moz-box-shadow: 0px 4px 50px $app_black_shade5;
    -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
    box-shadow: 0px 4px 50px $app_black_shade5;

    & > ul {
      & > li {
        background: transparent;

        &:hover {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.1) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .emojis {
    bottom: calc(100% + 4px);
    left: -20px;
  }
}
