@import '../../theme/color.scss';
@import '../../theme/commons.scss';

.file_preview {
  position: relative;
  padding: 0 10px;
  padding-top: 12px;
  padding-bottom: 8px;
  border-radius: 4px;
  // border: 2px solid red;
  box-sizing: border-box;
  background: $app_white_absolute;
  border: 1px solid $app_white_shade7;
  cursor: pointer;

  .raw {
    position: relative;
    display: inline-block;
    width: 275px;

    .link {
      position: absolute;

      & > a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-decoration: none;
      }
    }

    .type,
    .texts {
      position: relative;
      display: inline-block;
      vertical-align: top;
    }

    .type {
      height: 28px;
      width: 21px;
      overflow: hidden;
      // border: 2px solid red;
      box-sizing: border-box;

      & > img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }

    .texts {
      width: calc(100% - 50px);
      margin-left: 15px;

      & > h3 {
        position: relative;
        font-size: 11.4px;
        max-height: 34px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $app_black_shade4;
      }

      & > h5 {
        position: relative;
        margin-top: 2px;
        font-weight: 500;
        font-size: 10.5px;
        color: $app_gray_shade6;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .file_preview {
    .raw {
      .texts {
        & > h3 {
          font-size: 80%;
          max-height: 30px;
        }

        & > h5 {
          font-size: 78%;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .file_preview {
    .raw {
      width: 220px;

      .texts {
        & > h3 {
          max-height: 30px;
        }
      }
    }
  }
}
