@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/assets.scss';

.access_task_view {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;

  .actual {
    position: relative;
    padding: 10px 15px;
    padding-bottom: 35px;
    width: 370px;
    margin-left: calc((100% - 400px) / 2);
    border-radius: 6px;
    background: $app_white_absolute;
    border: 1px solid $app_white_shade3;
    box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
    -webkit-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
    -moz-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
    // border: 1px solid red;

    .close {
      position: absolute;
      right: 3px;
      top: 6px;
      display: inline-block;

      &_tip {
        position: absolute;
        top: 102%;
        right: 0;
        display: none;
      }

      &:hover {
        .close_tip {
          display: block;
        }
      }

      &_button {
        position: relative;
        width: 15px;
        height: 25px;
        background: transparent;

        &:hover {
          background: transparent;
          background-color: transparent;
        }
      }
    }

    .title {
      position: relative;
      margin-top: 5px;
      width: 100%;
      text-align: center;
      display: inline-block;

      & > h4 {
        font-weight: 800;
        text-align: center;
      }
    }

    .list {
      position: relative;
      width: 100%;
      display: inline-block;
      max-height: 170px;
      margin-top: 25px;
      padding: 4px 0;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-moz-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-moz-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 10px;
      }

      &::-moz-scrollbar-thumb {
        background: #e8e8e8;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #dbdbdb;
      }

      &::-moz-scrollbar-thumb:hover {
        background: #dbdbdb;
      }

      & > ul {
        list-style: none;
        list-style-type: none;
        position: relative;
        width: 100%;

        & > li {
          width: 100%;
          padding-left: 3px;
          box-sizing: border-box;
          position: relative;
          display: block;

          .list_access_type {
            display: inline-block;
            vertical-align: middle;
            font-size: 80%;
            font-weight: 700;
            border-radius: 5px;
            padding: 4px 6px;
            border: 1px solid $app_white_shade3;
            text-align: center;
            cursor: pointer;
            position: absolute;
            right: 0;
            margin-left: 0;
          }

          .avatar {
            display: inline-block;
            vertical-align: middle;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            overflow: hidden;

            &_spinner {
              &_raw {
                &_spin {
                  position: relative;
                  height: 30px;
                  width: 30px;
                }
              }
            }

            & > div:nth-child(1) {
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              position: relative;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              border-radius: 50%;
              text-align: center;
              border: 1px solid;
              box-sizing: border-box;
              border-radius: 50%;

              & > h5 {
                font-weight: 700;
                text-align: center;
                display: inline-block;
                position: relative;
                line-height: 28px;
              }
            }
          }

          .name {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 4px 5px;
            width: calc(100% - 150px);
            margin-left: 8px;
            line-height: 20px;
            font-weight: 600;
            font-size: 78%;
            box-sizing: border-box;
            overflow-wrap: break-word;
          }
        }

        & > li:not(:nth-child(1)) {
          margin-top: 8px;
        }
      }

      &_placeholder {
        position: relative;
        width: 100%;
        display: inline-block;
        padding: 5px 0;
        margin-top: 20px;

        & > ul {
          list-style: none;
          list-style-type: none;
          position: relative;
          width: 100%;

          & > li {
            position: relative;
            height: 12px;
            background: $app_gray_shade1;
            animation: opacity-load 2.3s ease-in-out infinite;
            border-radius: 20px;
            margin-left: 10px;
          }

          & > li:nth-child(2) {
            width: 40%;
          }

          & > li:nth-child(1) {
            width: 60%;
          }

          & > li:not(:nth-child(1)) {
            margin-top: 15px;
          }
        }
      }
    }

    .empty {
      position: relative;
      width: 100%;
      margin-top: 20px;
      padding: 5px 0;
      display: inline-block;
      text-align: center;

      & > h5 {
        text-align: center;
        position: relative;
        line-height: 18px;
        font-weight: 600;

        & > p {
          text-align: center;
          position: relative;
          line-height: 18px;
          font-weight: 600;

          a {
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .access_task_view {
    .actual {
      width: calc(95% - 20px);
      margin-left: 2.5%;
      padding: 8px 10px;
      padding-bottom: 25px;

      .list {
        & > ul {
          & > li {
            // border: 1px solid red;

            .name {
              // border: 1px solid red;
              width: calc(100% - 135px);
            }
          }
        }
      }
    }
  }
}
