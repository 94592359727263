@import '../../theme//commons.scss';
@import '../../theme/color.scss';

.logo_load {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;

  &_dark {
    background: $app_darkmode_background1;
  }

  .msg {
    position: relative;
    max-width: 290px;
    margin-top: 5px;

    & > h3 {
      font-weight: 700;
      text-align: center;
    }

    &_dark {
      & > h3 {
        color: $app_darkmode_text1;
      }
    }
  }
}
