@import '../theme/commons.scss';
@import '../theme/color.scss';
@import '../theme/scroll.scss';

.error_manager {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  display: inline-block;
  background: rgba($color: #000, $alpha: 0.4);
  z-index: 20;
  text-align: center;

  .error_message {
    position: relative;
    display: inline-block;
    width: 325px;
    padding: 8px 14px;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 220px;
    box-sizing: border-box;
    background: $app_white_absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border: 1.5px solid $app_white_shade3;
    border-radius: 7px;

    & > ul {
      position: relative;
      width: 100%;
      list-style: none;
      list-style-type: none;
      display: inline-block;
      min-height: 40px;
      padding: 0px 4px;
      box-sizing: border-box;
      max-height: 320px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 6px;

      @include custom_scroll;

      & > li {
        position: relative;
        width: 100%;

        & > h5 {
          position: relative;
          font-size: 13px;
          font-weight: 700;
          margin-bottom: 4px;
          text-align: left;
        }

        & > p {
          position: relative;
          text-align: left;
          font-size: 11.5px;
          font-weight: 600;
          letter-spacing: 0.4px;
          text-align: left;
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 14px;
      }
    }

    .close {
      position: absolute;
      top: 4px;
      right: 8px;
      display: inline-block;
      text-align: center;

      &_button {
        position: relative;
        background: transparent;
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
