@mixin custom_scroll {
  scrollbar-color: #e8e8e8 transparent;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-moz-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-moz-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 10px;
  }

  &::-moz-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb;
  }

  &::-moz-scrollbar-thumb:hover {
    background: #dbdbdb;
  }
}

@mixin custom_scroll_dark {
  scrollbar-color: rgba($color: #636363, $alpha: 0.5) transparent;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-moz-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-moz-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: #636363, $alpha: 0.5);
    border-radius: 10px;
  }

  &::-moz-scrollbar-thumb {
    background: rgba($color: #636363, $alpha: 0.5);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: #636363, $alpha: 0.35);
  }

  &::-moz-scrollbar-thumb:hover {
    background: rgba($color: #636363, $alpha: 0.35);
  }
}

@media screen and (max-width: 900px) {
  @mixin custom_scroll {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-moz-scrollbar {
      width: 4px;
    }
  }

  @mixin custom_scroll_dark {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-moz-scrollbar {
      width: 4px;
    }
  }
}
