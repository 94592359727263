@import '../../../theme/color.scss';
@import '../../../theme/task.scss';
@import '../../../theme/commons.scss';
@import '../../../theme/scroll.scss';

.task_description_raw {
  position: relative;
  width: 100%;
  height: 100%;
  // margin-top: 20px;
  // border: 5px solid violet;
  box-sizing: border-box;

  .footer {
    position: relative;
    width: 100%;
    margin-top: 5px;
    padding-left: 5px;
    box-sizing: border-box;

    & > h5 {
      font-weight: 600;
      font-size: 70%;
    }
  }

  .files_wrapper {
    position: relative;
    width: 100%;
  }

  .drag_file {
    position: absolute;
    height: calc(100% - 48px);
    width: 100%;
    left: 0;
    top: 48px;
    border: 2px dashed #d8dae4;
    border-radius: 0px 0px 4px 4px;
    box-sizing: border-box;
    background: linear-gradient(0deg, #fafbff, #fafbff), #ffffff;
    cursor: pointer;

    .icon {
      position: relative;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background: #edeff4;

      svg {
        height: 40px;
        width: 40px;
        fill: #c1c4d4;
        path {
          fill: #c1c4d4;
        }
      }
    }

    & > h1 {
      position: relative;
      text-align: center;
      margin-top: 12px;
      color: $app_gray_shade7;
      font-size: 120%;
      line-height: 33px;
    }
  }

  &_input {
    position: relative;
    height: calc(100% - 60px); // deduct for the title
    width: calc(100% - 26px);
    border: 2px solid rgba($color: $app_white_shade3, $alpha: 0.6);
    border-radius: 4px;
    padding: 10px 12px;
    display: block;
    box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.4);
    -moz-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.4);
    -webkit-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.4);
    transition: border 0.1s ease-out;

    &_dark {
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 2px solid rgba($color: $app_darkmode_text1, $alpha: 0.06);
    }

    &_active {
      border: 2px solid $app_white_shade7;

      &_dark {
        border: 2px solid rgba($color: $app_darkmode_text1, $alpha: 0.5);
      }
    }

    .scrolling_container {
      position: relative;
      height: calc(100% - 60px); // deduct for the formats toolbar
      width: calc(100% + 4px);
      box-sizing: border-box;
      display: block;
      margin-top: 50px;
      // border: 1px solid red;
      // min-height: 220px;
      // max-height: 600px;
      // border: 1px solid red;
    }

    .editable {
      position: relative;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid red;
      // @include custom_scroll;

      & > div:nth-child(1) {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        @include custom_scroll;

        // max-height: 420px;
        // max-height: 600px;
        // overflow-y: scroll;

        &::after,
        &::before {
          font-weight: 600;
          font-size: 95%;
          font-style: normal;
          position: absolute;
          left: 0;
          line-height: 28px;
          color: $app_gray_shade2;
        }

        @include task_description;
      }

      &_dark {
        & > div:nth-child(1) {
          @include task_description_dark;
        }
      }
    }

    .format {
      position: absolute;
      width: 100%;
      height: 50px;
      left: 0;
      right: 0;
      top: 0;
      box-sizing: border-box;
      border-bottom: 1.2px solid rgba($color: $app_white_shade3, $alpha: 0.6);
      // box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
      // -moz-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
      // -webkit-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);

      &_active {
        box-sizing: border-box;
      }

      &_dark {
        border-bottom: 2px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);

        &_active {
          border-bottom: 2px solid
            rgba($color: $app_darkmode_text1, $alpha: 0.3);
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .task_description_raw {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // border: 1px solid red;

    .footer {
      display: none;
      height: 0;
      overflow: hidden;
    }

    &_input {
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 0;
      box-sizing: border-box;
      border: none;
      padding: 10px 9px;
      padding-top: 58px;
      -webkit-box-shadow: none;
      box-shadow: none;
      // border: 2px solid red;

      &_active {
        border-top: none;
        border: none;

        &_dark {
          border: none;
        }
      }

      .scrolling_container {
        height: 100%;
        max-height: 100%;
        margin: 0;
        box-sizing: border-box;
        // margin-top: -50px; // formats
        // margin-bottom: 50px;

        .editable {
          position: relative;
          width: 100%;

          & > div:nth-child(1) {
            min-height: 100%;
            max-height: 100%;
          }

          &_default {
            min-height: 100%;
            height: 100%;
          }
        }
      }

      .format {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1.5px solid $app_white_shade2;

        &_dark,
        &_dark_active {
          border-bottom: 1.5px solid
            rgba($color: $app_darkmode_text1, $alpha: 0.2);
        }
      }
    }
  }
}
