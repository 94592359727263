@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';
@import '../../../theme/task.scss';
@import '../assets.scss';

.more_actions {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 35px;
  text-align: center;
  box-sizing: border-box;
  margin-right: 4px;
  margin-top: 5px;
  // border: 2px solid red;

  &_hidden {
    width: 0;
    overflow: hidden;
    opacity: 0;
  }

  .copied_link {
    right: 25%;
    top: calc(100% + 10px);
    z-index: $high_front;
  }

  .more_actions_hover {
    position: absolute;
    display: none;
    min-width: 120px;
  }

  &_ic {
    position: relative;
    height: 30px;
    width: 35px;
    left: 0;
    right: 0;
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;

    &_wrap {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      width: 35px;

      &:hover {
        .more_actions_hover {
          display: block;
        }
      }

      button {
        height: 30px;
        width: 35px;
        border: none;
      }
    }

    &_wrap {
      &_dark {
        &:hover {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.05
          ) !important;
        }
      }
    }

    & > ul {
      position: relative;
      height: 30px;
      width: 35px;
      left: 0;
      top: 0;
      text-align: center;
      list-style: none;
      list-style-type: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      margin: 0;

      & > li {
        position: relative;
        display: inline-block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: $app_black_shade2;
        margin: 0;
        left: 0;
        text-align: center;
      }

      & > li:nth-child(2),
      & > li:nth-child(3) {
        margin-left: 4px;
      }
    }

    &_dark {
      & > ul {
        & > li {
          background: $app_darkmode_text1;
        }
      }
    }
  }

  &_opts {
    position: absolute;
    top: calc(100% - 8px);
    right: 4px;
    z-index: $high_front;
    border: 1px solid $app_white_shade3;
    box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.5);
    -webkit-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.5);
    -moz-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.5);
    padding: 4px 2px;
    background: $app_white_absolute;
    border-radius: 4px;
    display: inline-block;

    .multiple_opts {
      & > li:not(:nth-child(1)) {
        margin-top: 2px;
      }
    }

    & > ul {
      position: relative;
      list-style: none;
      list-style-type: none;
      display: inline-block;
      text-align: left;
      padding: 0;

      .copy_link,
      .copy_link_w_pw,
      .update_state,
      .to_edit {
        position: relative;
        width: 100%;

        button {
          position: relative;
          // border: 1px solid red;
          width: 100%;
          border-radius: none;
          box-sizing: border-box;
          padding: 0;
          padding-left: 8px;
          padding-right: 24px;

          & > div:nth-child(1) {
            position: relative;
            width: 100%;
            text-align: left;
            // border: 1px solid red;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & > div:nth-child(1) {
              position: relative;
              height: 20px;
              width: 20px;
              box-sizing: border-box;
              margin-top: 0;
              margin-right: 8px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                height: 12px;
                width: 12px;
              }
            }

            & > h5 {
              font-weight: 700;
              font-size: 95%;
              display: inline-block;
              max-width: calc(100% - 30px);
              vertical-align: top;
              color: $app_black_shade4;
            }
          }
        }
      }

      .copy_link {
        min-width: 90px;
        max-width: 100%;
        white-space: nowrap;
      }

      .copy_link_w_pw {
        max-width: 220px;
        white-space: nowrap;
      }

      .copy_link,
      .update_state,
      .copy_link_w_pw {
        svg {
          fill: $app_gray_shade6;
          height: 12px;
          width: 12px;
        }
      }

      .to_edit {
        svg {
          fill: $app_gray_shade6;
          height: 14px;
          width: 14px;
        }
      }

      .update_state {
        &_loading {
          & > button {
            & > div:nth-child(1) {
              & > div:nth-child(1) {
                svg {
                  height: unset;
                  width: unset;
                }
              }

              & > h5 {
                opacity: 0.7;
              }
            }
          }
        }

        &_dark {
          & > button {
            & > div:nth-child(1) {
              & > div:nth-child(1) {
                svg {
                  circle {
                    stroke: $app_darkmode_text1 !important;
                  }
                }
              }
            }
          }
        }
      }

      & > li {
        position: relative;
        cursor: pointer;
      }
    }

    &_dark {
      background: $app_darkmode_background_header1;
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;

      & > ul {
        .copy_link,
        .copy_link_w_pw,
        .update_state,
        .to_edit {
          button {
            &:hover {
              background: rgba(
                $color: $app_darkmode_text1,
                $alpha: 0.05
              ) !important;
            }

            & > div:nth-child(1) {
              h5 {
                color: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .more_actions {
    &_opts {
      right: 5px;
    }
  }
}
