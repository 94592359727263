@import '../../../../../theme/color.scss';
@import '../../../../../theme/commons.scss';
@import '../../../../../theme/scroll.scss';

.gifs {
  position: absolute;
  width: 320px;
  padding: 2px 4px;
  border-radius: 5px;
  box-sizing: border-box;
  bottom: calc(100% - 12px);
  left: 16px;
  background: $app_white_absolute;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
  border: 1.5px solid $app_white_shade3;
  z-index: calc(#{$high_front} + 1);

  .search_gif {
    position: relative;
    width: 100%;
    margin: 8px 0;
    margin-top: 4px;
    box-sizing: border-box;

    & > input[type='text'] {
      width: 100%;
      color: $app_black_shade1;
      font-size: 12.5px!important;
      font-weight: 600;
      box-sizing: border-box;
      font-family: 'Manrope' !important;
      letter-spacing: 0.42px;
      padding-left: 32px;
    }

    & > div:last-child {
      position: absolute;
      height: 100%;
      width: 30px;
      top: 6.5px;
      left: 3px;
      z-index: $high_front;

      svg {
        height: 12px;
        width: 15px;
        fill: $app_black_shade3;

        path {
          height: 13px;
          width: 16px;
          fill: $app_black_shade3;
        }
      }
    }
  }

  .empty {
    position: relative;
    width: 100%;
    padding: 2px 4px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-top: 8px 0;

    & > h5 {
      position: relative;
      font-weight: 700;
      font-size: 90%;
      letter-spacing: 0.4px;
      color: $app_black_shade3;
    }

    &_dark {
      color: $app_darkmode_text1;
      & > h5 {
        color: $app_darkmode_text1;
      }
    }
  }

  & > ul {
    position: relative;
    min-height: 80px;
    max-height: 220px;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    user-select: none;
    -webkit-user-select: none;
    // overflow: hidden;
    overflow: hidden;
    overflow-y: scroll;
    white-space: nowrap;
    box-sizing: border-box;
    padding-right: 4px;

    @include custom_scroll;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-moz-scrollbar {
      height: 7px;
    }

    & > li {
      position: relative;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      display: block;
      cursor: pointer;
      overflow: hidden;
      //   border: 2px solid red;

      & > img {
        position: relative;
        height: 100%;
        width: 100%;
        transition: transform 0.2s ease;
      }

      &:hover {
        & > img {
          transform: scale(1.05);
        }
      }
    }

    & > li:not(:nth-child(1)) {
      margin-top: 6px;
    }
  }
}
@media screen and (max-width: 900px) {
  .gifs {
    width: 285px;
    bottom: calc(100% + 4px);
    left: -30px;
  }
}
