@import '../../theme/color.scss';
@import '../../theme/commons.scss';

.image_preview_uploaded {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: calc(#{$high_front} + 6);
  box-sizing: border-box;
  background: rgba($color: #000000, $alpha: 0.5);

  & > img {
    position: relative;
    object-position: center;
    object-fit: contain;
    max-height: 90%;
    position: relative;
    max-width: 680px;
    min-width: 400px;
    border-radius: 8px;
  }

  .close {
    position: absolute;
    right: 12px;
    top: 16px;

    & > button {
      background: rgba($color: $app_black_shade1, $alpha: 0.8);
      position: relative;

      &:hover,
      &:active {
        background: rgba($color: $app_black_shade1, $alpha: 0.5);
      }

      svg {
        height: 16px;
        width: 16px;
        fill: #fff;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .image_preview_uploaded {
    & > img {
      max-width: 650px;
    }
  }
}

@media screen and (max-width: 650px) {
  .image_preview_uploaded {
    & > img {
      max-width: 475px;
    }
  }
}

@media screen and (max-width: 480px) {
  .image_preview_uploaded {
    & > img {
      max-width: 370px;
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 380px) {
  .image_preview_uploaded {
    & > img {
      max-width: 340px;
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 345px) {
  .image_preview_uploaded {
    & > img {
      max-width: 320px;
      min-width: 300px;
    }
  }
}
