@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.task_password {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: rgba($color: $app_white_shade3, $alpha: 0.1);

  &_raw {
    display: inline-block;
    position: relative;
    padding: 28px 20px;
    min-height: 70px;
    width: 460px;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    border-radius: 4px;
    background: $app_white_absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border: 1.5px solid $app_white_shade3;

    .logo {
      & > div:nth-child(1) {
        height: 30px;
        width: 30px;

        & > div {
          height: 30px;
          width: 30px;
        }
      }

      & > p:nth-child(2) {
        font-size: 22px;
      }

      &_wrap {
        position: relative;
        display: inline-block;
        text-align: center;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
        }
      }
    }

    .message {
      position: relative;
      width: 100%;
      text-align: center;
      padding: 5px 80px;
      margin-top: 5px;
      box-sizing: border-box;

      & > h5 {
        display: inline-block;
        font-weight: 600;
        line-height: 20px;
        color: $app_gray_shade2;
      }

      &_dark {
        & > h5 {
          color: $app_darkmode_text1;
        }
      }
    }

    &_input {
      margin-top: 4px;
      margin-bottom: 30px;
      position: relative;
      width: 200px;
      margin-left: calc((100% - 200px) / 2);
      box-sizing: border-box;
      text-align: center;

      .password {
        position: relative;
        width: 200px;
        box-sizing: border-box;
        font-size: 80% !important;
        font-weight: 500 !important;
        font-family: 'Manrope' !important;
      }

      &_dark {
        input[type='text'],
        input[type='password'] {
          background: transparent;
          border: 1px solid $app_darkmode_border1;
          background: $app_darkmode_background3;
          color: $app_darkmode_text1;
        }
      }
    }

    .tries {
      position: relative;
      width: 100%;
      margin-top: 2px;
      margin-bottom: 2px;
      display: inline-block;
      text-align: center;

      & > div:nth-child(1) {
        display: inline-block;
        text-align: center;

        & > h5 {
          p {
            font-size: 85%;
            color: $app_gray_shade2;
            font-weight: 600;
            letter-spacing: 0.4px;

            & > span {
              color: $app_gray_shade2;
              font-weight: 600;
            }
          }
        }
      }

      &_dark {
        & > div:nth-child(1) {
          & > h5 {
            p {
              color: $app_darkmode_text1;

              span {
                color: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }

    .spinner_wrap {
      position: relative;
      width: 100%;
      margin-top: 20px;

      .raw {
        position: relative;
        margin-left: calc((100% - 30px) / 2);

        &_spin {
          position: relative;
          height: 30px;
          width: 30px;
        }
      }
    }

    .status_wrap {
      position: relative;
      width: 100%;
      display: inline-block;
      overflow-wrap: break-word;
      text-align: center;
      margin-top: 15px;

      & > h5 {
        position: relative;
        text-align: center;
        display: inline-block;
        font-weight: 700;
        line-height: 22px;
      }
    }

    .actions {
      position: relative;
      width: 100%;

      .submit_button {
        position: relative;
        width: 200px;
        height: 35px;
      }
    }

    &_dark {
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: 0px 4px 30px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 30px $app_black_shade5;
      box-shadow: 0px 4px 30px $app_black_shade5;
      background: $app_darkmode_background_header1;
    }
  }
}

@media screen and (max-width: 550px) {
  .task_password {
    &_raw {
      width: 95%;
      padding: 24px 18px;

      .message {
        box-sizing: border-box;
        padding: 0 20%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .task_password {
    &_raw {
      width: 95%;
      padding: 24px 18px;

      .message {
        padding: 0 10%;
      }
    }
  }
}
