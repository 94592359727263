@import '../../../theme/color.scss';
@import '../../../theme/commons.scss';

.payment_success {
  position: relative;
  height: 100vh;
  width: 100%;

  .raw {
    position: relative;
    width: 440px;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    background: $app_white_absolute;
    border-bottom: 1.2px solid rgba($color: $app_white_shade3, $alpha: 0.6);
    box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
    -moz-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
    -webkit-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);

    .success {
      &_ic,
      .title,
      .message,
      .to_dashboard {
        position: relative;
        width: 100%;
      }

      &_ic {
        justify-content: flex-start;

        svg {
          height: 30px;
          width: 30px;
        }

        &_logo {
          margin: 30px 0;
        }
      }

      .title {
        margin-top: 10px;
        text-align: left;
        margin-top: 30px;

        & > h3 {
          font-weight: 700;
          font-size: 180%;
        }
      }

      .message {
        margin-top: 20px;

        & > h5 {
          font-weight: 500;
          font-size: 14px;
        }
      }

      .loading {
        position: relative;
        width: 100%;
        margin: 10px 0;

        & > div {
          position: relative;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          & > div {
            height: 30px;
            width: 30px;
          }

          svg {
            height: 30px;
            width: 30px;
            circle {
              height: 30px;
              width: 30px;
            }
          }
        }

        &_dark {
          & > div {
            svg {
              circle {
                stroke: $app_white_light !important;
              }
            }
          }
        }
      }

      .redirect {
        margin-top: 30px;

        span,
        h5 {
          position: relative;
          display: inline-block;
          vertical-align: middle;
        }

        h5 {
          font-size: 12px;
        }

        span {
          margin-left: 6px;
          margin-top: 1.5px;

          svg {
            height: 12px;
            width: 12px;
          }
        }
      }

      .to_dashboard {
        margin-top: 30px;

        &_button {
          height: 35px;
          padding-right: 40px;
          padding-left: 40px;
        }
      }
    }

    &_dark {
      background: $app_darkmode_background_header1;
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
      border: none;
    }
  }
}

@media screen and (max-width: 445px) {
  .payment_success {
    .raw {
      width: 92%;
    }
  }
}
