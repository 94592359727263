@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../app-logo/Annex.scss';
@import './Annex.scss';

.app_logo_icon {
  position: relative;

  .fav_icon {
    position: relative;
    height: 40px;
    width: 40px;
    overflow: hidden;
    user-select: none;
    box-sizing: border-box;
    -webkit-user-select: none;

    & > div:nth-child(1) {
      position: relative;
      height: 40px;
      width: 40px;
      background: url($logo_ic);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &_dark {
      & > div:nth-child(1) {
        background: url($app_logo_only_white);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  .beta_title {
    position: absolute;
    bottom: -22px;
    right: -3px;
    padding: 1px 8px;
    border-radius: 3px;
    border: 1px solid $app_dominant_purple_bg;
    text-align: center;
    display: inline-block;

    & > h5 {
      color: $app_dominant_purple;
      font-weight: 700;
      font-size: 9.5px;
    }
  }
}
