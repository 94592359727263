@import '../../../theme/color.scss';

.reminders {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 8px;
  margin-top: 8px;
  display: inline-block;
  text-align: left;
  // border: 2px solid red;

  & > div:nth-child(1) {
    position: relative;
    padding: 4px 8px;
    display: inline-block;
    background: #dcf2ea;
    border-radius: 4px;

    & > h5 {
      position: relative;
      font-size: 85%;
      font-weight: 600;
      text-align: center;
      color: #317159;
    }
  }

  &_dark {
    & > div:nth-child(1) {
      & > h5 {
        position: relative;
      }
    }
  }
}
