@import '../../../theme/color.scss';
@import '../../../theme/commons.scss';

.files_preview {
  position: relative;
  width: 100%;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: inline-block;

  & > ul {
    position: relative;
    list-style-type: none;
    list-style: none;
    width: 100%;
    float: left;

    & > li {
      position: relative;
      margin: 0 8px;
      margin-top: 16px;
      // border: 2px solid red;
      box-sizing: border-box;
      display: inline-block;
    }
  }
}

@media screen and (min-width: $max_width_fix) {
  .files_preview {
    width: 765px;
    margin-left: calc((100% - 870px - 200px) / 2);
  }
}

@media screen and (max-width: $max_width_fix) {
  .files_preview {
    width: 780px;
    box-sizing: border-box;
    margin-left: calc((100% - 870px - 200px) / 2);
  }
}

@media screen and (max-width: 1140px) {
  .files_preview {
    width: 690px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .files_preview {
    width: 650px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 990px) {
  .files_preview {
    width: 620px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 949px) {
  .files_preview {
    width: 92%;
    margin-left: 4%;
  }
}
