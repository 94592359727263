@import '../../../theme/commons.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/color.scss';

.advanced_options {
  position: relative;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;

  &_title {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: left;

    & > div:nth-child(1) {
      display: inline-block;
      position: relative;
      width: 100%;

      button {
        display: inline-block;
        text-align: left;
        width: 100%;
        // border: 1px solid red;

        & > h4,
        & > div:nth-child(2) {
          display: inline-block;
          vertical-align: middle;
        }

        & > h4 {
          position: relative;
          line-height: 20px;
          text-align: center;
          margin-right: 3px;
          font-weight: 600;
          color: $app_gray_shade2;
        }

        & > div:nth-child(2) {
          position: absolute;
          right: 0;
          margin-top: 2px;
          display: inline-block;
          padding: 2px;
          transition: transform 0.12s ease-out;

          svg {
            fill: $app_gray_shade2;
            path {
              fill: $app_gray_shade2;
            }
          }
        }
      }
    }

    &_expand {
      & > div:nth-child(1) {
        button {
          & > div:nth-child(2) {
            margin-top: -1.5px;
            transform: rotate(-180deg);
          }
        }
      }
    }

    &_dark {
      & > div:nth-child(1) {
        button {
          & > h4 {
            color: $app_darkmode_text1;
          }

          & > div:nth-child(2) {
            svg {
              fill: $app_darkmode_text1;

              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }
  }

  &_content {
    position: relative;
    width: 100%;
    margin-top: 15px;
    display: inline-block;
    box-sizing: border-box;
    transition: height 0.2s ease-in-out;

    .allow_comments,
    .allow_subs_edit {
      position: relative;
      width: 100%;
      display: inline-block;

      .cb {
        position: relative;
        width: 100%;
        margin: 0;

        .raw {
          padding: 0;
          margin: 0;
          svg {
            path {
              fill: white;
            }
          }
        }

        &_dark {
          label {
            span {
              color: $app_darkmode_text1;
            }

            & > div {
              background: transparent !important;

              svg {
                fill: transparent !important;
                path {
                  fill: transparent !important;
                }
              }
            }
          }
        }

        &_active_dark {
          label {
            & > div {
              background: $app_blue_shade3 !important;

              svg {
                fill: $app_white_shade3 !important;

                path {
                  fill: $app_white_shade3 !important;
                }
              }
            }
          }
        }
      }
    }

    .allow_subs_edit {
      margin-top: 12px;
    }

    &_show {
      height: unset;
    }

    &_hide {
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  &_placeholder {
    position: relative;
    width: 100%;
    display: inline-block;

    & > ul {
      position: relative;
      width: 100%;
      list-style: none;
      list-style-type: none;
      padding: 5px 0;
      box-sizing: border-box;

      & > li {
        position: relative;
        height: 10px;
        border-radius: 15px;
        background: $app_gray_shade1;
        animation: opacity-load 2.3s ease-in-out infinite;
      }

      & > li:nth-child(1) {
        width: 70%;
        margin-left: 15%;
      }

      & > li:nth-child(2) {
        width: 40%;
        margin-left: 30%;
      }

      & > li:not(:nth-child(1)) {
        margin-top: 10px;
      }
    }
  }
}
