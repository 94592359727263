@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';

.activities {
  position: relative;
  margin-top: 10px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 100px;

  &_need_login {
    position: relative;
    width: 100%;
    margin: 20px 0;

    & > h5 {
      position: relative;
      font-weight: 600;
      font-size: 90%;
    }
  }

  & > ul {
    position: relative;
    width: 100%;
    list-style: none;
    list-style-type: none;

    & > li {
      position: relative;
      width: 100%;
      padding: 4px 8px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      .item,
      .author {
        position: relative;
      }

      .author {
        margin-left: 8px;
      }
    }

    & > li:not(:nth-child(1)) {
      margin-top: 22px;

      &::before {
        position: absolute;
        height: 14px;
        width: 2px;
        background: $app_gray_shade4;
        bottom: calc(100% + 2px);
        border-radius: 8px;
        content: ' ';
        display: block;
        opacity: 0.3;
      }
    }

    & > li:last-child {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 900px) {
  .activities {
    &_need_login {
      margin-bottom: 50px;
    }
  }
}
