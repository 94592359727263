pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  overflow-x: scroll;
  padding: 6px 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.ql-video {
  position: relative;
  width: 100%;
  display: flex !important;
  justify-content: center;
  box-sizing: border-box;
}

.ql-video > video {
  position: relative;
  width: 460px;
  box-sizing: border-box;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .ql-video > video {
    width: 600px;
  }
}

@media screen and (max-width: 900px) {
  .ql-video > video {
    width: 90%;
  }
}
