html {
  scrollbar-color: #e8e8e8 transparent;
  box-sizing: border-box;
  padding: 0;
}

body {
  position: relative;
  display: inline-block;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  box-sizing: border-box;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  color: #323333;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
}

.darkmode_background {
  background: #1d232a !important;
}

@media screen and (max-width: 900px) {
  body,
  html {
    font-size: 13px;
  }

  body {
    height: 100vh;
    min-height: unset;
    overflow-x: unset;
  }

  #root {
    height: 100%;
    width: 100%;
    font-size: 13px;
  }
}
