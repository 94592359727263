@import '../../theme/color.scss';
@import '../../theme/commons.scss';

.footer {
  position: absolute;
  height: 30px;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-top: 20px;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 0 30px;
  background: transparent;
  border-top: 2px solid rgba($color: #e6e8f0, $alpha: 0.4);
  overflow: hidden;

  &_dark {
    background: $app_darkmode_background1;
    border-top: 2px solid rgba($color: $app_darkmode_text1, $alpha: 0.05);
  }

  .chamu {
    position: relative;

    & > h5 {
      font-size: 75%;
      font-weight: 500;
      color: $app_black_shade1;
    }

    &_dark {
      & > h5 {
        color: $app_darkmode_text1;
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .footer {
    position: relative;

    &_read_only {
      position: absolute;
      bottom: 10px;
    }
  }
}
