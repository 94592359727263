@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';
@import '../../../theme/task.scss';
@import '../TaskAnnex.scss';

.task_actions_wrap {
  position: fixed;
  margin: 0;
  display: inline-block;
  width: 210px;
  top: 200px;
  margin-left: 10px;
  z-index: $high_front;
  box-sizing: border-box;

  .actions_placeholder {
    position: relative;
    width: 100%;
    margin-top: 15px;

    & > ul {
      position: relative;
      display: inline-block;
      width: calc(100% - 15px);
      padding: 0 7px;
      list-style: none;
      list-style-type: none;

      & > li {
        position: relative;
        height: 12px;
        background: $app_gray_shade1;
        animation: opacity-load 2.3s ease-in-out infinite;
        border-radius: 20px;
      }

      & > li:nth-child(1) {
        width: 40%;
      }

      & > li:nth-child(2) {
        width: 60%;
      }

      & > li:not(:nth-child(1)) {
        margin-top: 15px;
      }
    }

    &_dark {
      & > ul {
        & > li {
          background: $app_darkmode_background_header1;
        }
      }
    }
  }
}

@media screen and (min-width: $max_width_fix) {
  .task_actions_wrap {
    width: 290px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 949px) {
  .task_actions_wrap {
    display: none;
    // height: 0;
    //overflow: hidden;
    top: 0;
    margin: 0;
    z-index: unset;
    position: relative;
    width: 100%;
    display: inline-block;

    .actions_placeholder {
      display: none;
      overflow: hidden;
      height: 0;
      width: 0;
    }

    .exposure_info {
      display: none;
      overflow: hidden;
    }
  }
}
