@import '../theme/commons.scss';

.adjustable_absolute {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: inline-block;
}
