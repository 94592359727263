@import '../../../theme/color.scss';
@import '../../../theme/commons.scss';

.at_info {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: default;
  font-size: 105%;
  margin: 0 4px;

  &_hover {
    position: absolute;
    background: $app_black_shade1;
    padding: 4px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: calc(#{$high_front} + 2);
    display: none;

    &_avatar,
    &_texts {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
    }

    &_avatar {
      height: 34px;
      width: 34px;
      margin-top: 4px;
      border: none;
      border-radius: 50%;
      background: rgba($color: $app_black_shade2, $alpha: 0.4);

      & > img {
        position: relative;
        height: 34px;
        width: 34px;
        border-radius: 50%;
        background: transparent;
        object-fit: cover;
        border: none;
        outline: none;
        object-position: center;
      }

      &_default {
        position: relative;
        height: 34px;
        width: 34px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > h6 {
          position: relative;
          color: white;
          font-size: 95%;
          font-weight: 700;
        }
      }
    }

    &_texts {
      min-width: 120px;
      max-width: 240px;
      margin-left: 4px;

      & > h6 {
        position: relative;
        color: $app_white_absolute;
        margin: 0;
      }

      & > h6:nth-child(1) {
        font-weight: 600;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & > h6:nth-child(2) {
        font-size: 12px;
        opacity: 0.8;
        padding-left: 2px;
        box-sizing: border-box;
      }

      &_center {
        margin-top: 4px;
      }
    }

    &_noedit {
      top: unset;
      bottom: calc(60% - 5px);
      left: 25%;
    }
  }

  & > span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: $app_purple_shade2 !important;
    font-size: 105% !important;
    margin-bottom: 3.5px !important;
    text-align: center;
    font-weight: 700;
    // border: 2px solid red !important;
  }

  &_onhover {
    &:hover {
      .at_info_hover {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  &_noedit {
    & > span {
      font-size: 105% !important;
      margin-bottom: 3px !important;
    }
  }

  &_show {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 900px) {
  .at_info {
    & > span {
      font-size: 14px !important;
      margin-bottom: 4px !important;
    }

    &_hover {
      // top: calc(50% + 16px);
      bottom: calc(50% + 16px);
      top: unset;
      left: unset;
      right: unset;
    }

    &_noedit {
      & > span {
        font-size: 14px !important;
        margin-bottom: 3px !important;
      }
    }
  }
}
