@import '../../theme/color.scss';
@import '../../theme/commons.scss';

.copied_prompt {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  display: inline-block;
  text-align: center;
  z-index: $high_front;

  & > div:nth-child(1) {
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 7px 11px;
    border-radius: 6px;
    background: $app_black_shade1;

    & > h5 {
      position: relative;
      font-weight: 600;
      font-size: 90%;
      text-align: center;
      color: $app_white_absolute;
    }
  }
}
