@import '../../theme/color.scss';
@import '../../theme/commons.scss';

.link_migrate {
  position: absolute;
  min-height: calc(100vh + 50px);
  top: 0;
  left: 0;
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;

  .content {
    position: relative;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .logo {
      position: relative;
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        position: relative;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
      }
    }

    .texts {
      position: relative;
      margin-top: 20px;

      & > h2 {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        max-width: 320px;
        line-height: 25px;
        letter-spacing: 0.5px;
      }
    }

    .cta {
      position: relative;
      margin-top: 16px;

      & > button {
        position: relative;
        height: 40px;
        padding: 10px 25px;
        border: 2px solid #4769e4;
        border-radius: 100px;

        p {
          font-size: 115%;
          font-weight: 700;
          color: #4769e4;
        }

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .link_migrate {
    .content {
      width: 96vw;
    }
  }
}
