@import '../../../../theme/commons.scss';
@import '../../../../theme/color.scss';
@import '../../../../theme/scroll.scss';

.schedule_reminders {
  position: relative;
  width: 100%;
  // border: 2px solid red;
  box-sizing: border-box;
  margin: 6px 0;
  margin-top: 12px;

  .label {
    position: relative;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    // border: 2px solid red;

    .cb {
      margin: 0;
      padding: 2px 0;

      span {
        font-size: 86%;
        font-weight: 500;
      }

      svg {
        path {
          fill: $app_white_absolute;
        }
      }

      &_dark {
        span {
          color: $app_darkmode_text1;
        }

        & > div {
          background: transparent !important;

          svg {
            fill: transparent !important;
            path {
              fill: transparent !important;
            }
          }
        }
      }

      &_active_dark {
        & > div {
          background: $app_blue_shade3 !important;

          svg {
            fill: $app_white_shade3 !important;

            path {
              fill: $app_white_shade3 !important;
            }
          }
        }
      }
    }
  }

  .calendar {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: left;

    &_input {
      position: relative;
      height: 35px;
      width: 100%;
      background: $app_white_absolute;
      border: 1.5px solid $app_white_shade3;
      border-radius: 5px;
      padding: 0px;
      padding-left: 2px;
      box-sizing: border-box;
      margin-top: 8px;
      display: inline-block;
      text-align: center;
      text-align: left;

      &_value,
      &_time {
        height: 100%;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
      }

      &_time {
        width: calc(35% - 5px);
        margin: 0;
        padding: 0;
        margin-right: 8px;
        text-align: left;

        & > div {
          height: 100%;

          & > div {
            border: none;
            outline: none;
            background: transparent;
            font-size: 11px;

            & > div {
              & > span:nth-child(3) {
                margin-left: 3px;
                margin-right: 3px;
              }

              & > span:nth-child(4),
              & > span:nth-child(2) {
                margin-bottom: 1px;
              }
            }
          }
        }
      }

      &_value {
        max-width: calc(65% - 45px);
        margin: 0;
        padding: 0;
        margin-right: 4px;
        text-align: left;
        box-sizing: border-box;

        & > button {
          position: relative;
          margin: 0;
          margin-top: 0;
          padding: 0;
          padding-left: 4px;
          padding-right: 10px;
          box-sizing: border-box;
          width: 100%;
          justify-content: flex-start;

          & > h5 {
            position: relative;
            font-size: 10.5px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: left;
            color: $app_black_shade2;
            letter-spacing: 0.4px;
          }
        }
      }

      &_icon {
        height: 35px;
        width: 35px;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        right: 0;

        & > button {
          position: relative;
          height: 32px;
          width: 35px;
          padding: 0;
          margin: 0;
          box-sizing: border-box;

          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    &_drop {
      background: $app_white_absolute;
      position: absolute;
      top: -70px;
      left: -2px;
      width: calc(100% + 4px);
      z-index: $high_front;
      display: inline-block;
      padding: 0px;
      padding-bottom: 4px;
      box-sizing: border-box;
      background: $app_white_absolute;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.06);
      -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.06);
      border: 1.5px solid $app_white_shade3;
      border-radius: 4px;

      .close_calendar {
        position: relative;
        width: 100%;
        height: 25px;
        margin: 4px 0;
        // border: 2px solid red;
        box-sizing: border-box;
        justify-content: flex-end;

        &_button {
          position: relative;
          height: 25px;
          width: 25px;
          padding: 0;
          margin: 0;
          margin-right: 4px;
          box-sizing: border-box;

          svg {
            height: 12px;
            width: 12px;
          }
        }
      }

      & > div:nth-child(2) {
        position: relative;
        width: 100%;
        box-sizing: border-box;

        & > div:nth-child(1) {
          position: relative;
          width: 100%;
          height: 35px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            background: transparent;
            border: none;
            padding: 8px 0;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.2);
            }
            // color: $app_darkmode_text1 !important;
          }

          & > button:nth-child(1),
          & > button:nth-child(2),
          & > button:nth-child(4),
          & > button:nth-child(5) {
            width: 16px;
          }

          & > button:nth-child(3) {
            position: relative;
          }
        }
        & > div:nth-child(2) {
          button {
            background: transparent;
            border: none;
            box-sizing: border-box;
            cursor: pointer;
            padding: 4px 0;

            &:hover {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.2);
            }

            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }

      &_dark {
        background: $app_darkmode_background_header1;
        border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
        -moz-box-shadow: 0px 4px 50px $app_black_shade5;
        -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
        box-shadow: 0px 4px 50px $app_black_shade5;
        // border: 2px solid red;
      }
    }
  }
}
