@import '../../../theme/commons.scss';
@import '../../../theme/brand.scss';
@import '../../../theme/color.scss';

.docs_reminders {
  position: relative;
  width: 100%;

  .label {
    width: 100%;
    text-align: left;

    & > h2 {
      position: relative;
      display: inline-block;
      max-width: 100%;
      font-weight: 800;
      font-size: 200%;
    }
  }

  .message {
    width: 100%;
    text-align: left;
    margin-top: 10px;

    & > p:not(:nth-child(1)) {
      margin-top: 10px;
    }

    & > p {
      line-height: 27px;

      span,
      a {
        font-weight: 800;
        text-decoration: none;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
