@import '../../../theme/commons.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/color.scss';
@import '../../../theme/task.scss';
@import '../TaskAnnex.scss';

.exposure_info {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;

  &_actual {
    position: relative;
    right: 0;
    display: flex;
    //border: 1px solid red;

    .is_pub,
    .is_secure_pw,
    .is_private {
      position: relative;
      display: inline-block;

      & > div:nth-child(2) {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        height: 18px;
        width: 18px;
        margin-bottom: 2px;
      }

      & > h5 {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;
        line-height: 20px;
        margin-right: 5px;
        width: auto;
      }
    }

    .is_private {
      & > div:nth-child(2) {
        height: unset;
        width: unset;
        margin: 0;

        svg {
          fill: $app_gray_shade6;
          height: 14px;
          width: 14px;
        }
      }

      & > h5 {
        vertical-align: middle;
        line-height: unset;
        box-sizing: border-box;
      }
    }

    .is_pub {
      & > div:nth-child(2) {
        position: relative;
        // border: 2px solid red;
        box-sizing: border-box;
        margin-top: 3px;

        svg {
          fill: $app_gray_shade6;
          height: 16px;
          width: 16px;
        }
      }
    }

    .is_secure_pw {
      margin-bottom: 5px;

      & > div:nth-child(2) {
        position: relative;
        display: inline-block;
        text-align: center;

        svg {
          fill: $app_gray_shade6;
        }
      }

      & > h5 {
        width: auto;
        display: inline-block;
      }

      &_show {
        position: relative;
        margin-top: 10px;
        margin-bottom: 5px;
        display: block;
        // display: flex;
        // border: 1px solid red;
        box-sizing: border-box;
        padding: 0 10px;

        p {
          top: 0;
          right: 0;
          box-sizing: border-box;
          display: inline-block;
          letter-spacing: 0.5px;
          padding: 0;

          // &:hover {
          //   background: rgba($color: $app_white_light, $alpha: 0.6);
          // }
        }

        &_dark {
          border: 1px solid transparent;
          box-sizing: border-box;
          background: rgba($color: $app_darkmode_text1, $alpha: 0.1);

          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          }
        }
      }

      &_key {
        display: block;

        &_ic {
          display: inline-block;
          position: relative;
          margin-bottom: 2px;
          vertical-align: middle;
          text-align: center;
          box-sizing: border-box;
          transform: scaleX(-1) scaleY(-1);

          svg {
            fill: $app_gray_shade6;
            height: 14px;
            width: 14px;
          }
        }

        & > p {
          display: inline-block;
          vertical-align: middle;
          font-size: 85%;
          font-weight: 700;
          margin-left: 5px;
          letter-spacing: 0.4px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .exposure_info {
    &_actual {
      .is_pub {
        margin-bottom: 0;
        margin-top: 2px;
      }
    }
  }
}
