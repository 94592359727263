@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.load_spinner {
  position: relative;
  height: 50px;
  width: 50px;

  .ring_parent {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      animation-delay: -0.4;
    }

    & > div:nth-child(2) {
      animation-delay: -0.3;
    }

    & > div:nth-child(3) {
      animation-delay: -0.12s;
    }
  }

  .ring {
    display: block;
    position: absolute;
    height: 90%;
    width: 90%;
    left: 5%;
    top: 5%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid rgba($color: $app_dominant_purple, $alpha: 1);
    -webkit-animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite; /* Safari */
    -moz-animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  &_dark {
    .ring {
      border: 7px solid #f3f3f3;
      border-top: 7px solid rgba($color: #f3f3f3, $alpha: 0.4);
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
