@import '../../../../theme/commons.scss';
@import '../../../../theme/color.scss';
@import '../../../../theme/scroll.scss';

.stored_file {
  position: relative;
  padding: 4px 12px;
  padding-right: 10px;
  border-radius: 20px;
  background: $app_white_shade4;
  border: 1px solid #d8dae4;
  box-sizing: border-box;
  cursor: pointer;

  .file_link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .filename_tip {
    position: absolute;
    bottom: calc(100% + 10px);
    left: -10px;
    top: unset;
    display: none;

    & > div {
      & > h5 {
        max-width: 305px;
        font-size: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:hover {
    .filename_tip {
      display: block;
    }
  }

  .icon,
  & > h5 {
    // border: 2px solid red;
    position: relative;
    box-sizing: border-box;
  }

  & > h5 {
    font-size: 12px;
    font-weight: 600;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    margin-right: 4px;
    margin-top: 2px;

    .spinner {
      position: relative;
      // border: 1px solid red;
      margin-top: 0;

      & > div:nth-child(1) {
        height: 16px;
        width: 16px;

        & > div {
          height: 16px;
          width: 16px;

          & > div {
            border-width: 2px;
          }
        }
      }
    }

    svg {
      height: 14px;
      width: 14px;
    }
  }

  .remove {
    position: relative;
    margin-left: 5px;
    // border: 2px solid red;
    box-sizing: border-box;

    svg {
      height: 12px;
      width: 12px;
    }

    &_more_view {
      position: absolute;
      right: 12px;
    }
  }

  &_more_view {
    & > h5 {
      max-width: 140px;
    }
  }

  &_dark {
    background: rgba($color: $app_darkmode_background_header1, $alpha: 0.6);
    border: 1.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.2);

    & > h5 {
      color: $app_darkmode_text1;
    }

    .remove {
      svg {
        fill: $app_darkmode_text1;
        path {
          fill: $app_darkmode_text1;
        }
      }
    }
  }
}

.files {
  position: relative;
  width: 100%;
  // border: 2px solid red;
  box-sizing: border-box;
  margin: 4px 0;

  .show_more {
    position: absolute;
    bottom: 50%;
    left: 50px;
    background: $app_white_absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
    border: 1.5px solid $app_white_shade3;
    border-radius: 8px;
    padding: 10px 16px;
    box-sizing: border-box;
    display: inline-block;
    z-index: $normal_front;
    max-height: 220px;
    overflow-y: scroll;

    @include custom_scroll;

    & > ul {
      position: relative;
      display: inline-block;
      list-style: none;
      list-style-type: none;

      & > li {
        position: relative;
        min-width: 220px;
      }

      & > li:not(:nth-child(1)) {
        margin-top: 4px;
      }
    }

    &_dark {
      background: $app_darkmode_background_header1;
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
    }
  }

  & > ul {
    position: relative;
    list-style-type: none;
    list-style: none;
    float: left;

    & > li {
      display: inline-block;
      vertical-align: middle;
      margin-top: 3px;
    }

    .see_more {
      button {
        padding: 4px 8px;
        height: unset;
        min-height: unset;
        line-height: unset;

        & > h5 {
          position: relative;
          font-weight: 700;
        }
      }

      &_dark {
        button {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.02);
          & > h5 {
            color: $app_darkmode_text1;
            font-weight: 700;
          }

          &:hover,
          &:active {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.1);
          }
        }
      }
    }

    & > li:not(:nth-child(1)) {
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 950px) {
  .stored_file {
    & > h5 {
      max-width: 70px;
    }

    &_more_view {
      & > h5 {
        max-width: 155px;
      }
    }
  }

  .files {
    .show_more {
      max-height: 200px;
      left: unset;
      right: 0;

      & > ul {
        & > li {
          max-width: 240px;
          min-width: 240px;
        }
      }
    }

    & > ul {
      & > li {
        margin-top: 6px;
      }

      & > li:not(:nth-child(1)) {
        margin-left: 10px;
      }
    }
  }
}
