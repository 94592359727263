@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/brand.scss';
@import '../../theme/assets.scss';

.sign_upv2 {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  padding: 20px 0;

  .actual {
    position: relative;
    display: inline-block;
    width: 450px;
    margin: 0;
    margin-left: calc((100% - 450px) / 2);
    padding: 20px 15px;
    padding-bottom: 25px;
    box-sizing: border-box;
    border-radius: 6px;
    background: $app_white_absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border: 1.5px solid $app_white_shade3;

    .logo {
      width: 100%;
      display: inline-block;
      text-align: center;

      &_wrap {
        position: relative;
        display: inline-block;
        text-align: center;
      }
    }

    .success_signup {
      position: relative;
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 15px;

      & > div:nth-child(1) {
        position: relative;
        display: inline-block;
        position: relative;
        text-align: center;

        svg {
          height: 20px;
          width: 20px;
        }

        // background: url($celebrate_icon_black);
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: 60% 60%;
      }

      & > h3,
      & > h4 {
        font-weight: 700;
        text-align: center;
        margin-top: 5px;
      }

      & > h5 {
        position: relative;
        text-align: center;
        margin-top: 10px;
        font-weight: 700;
        color: $app_gray_shade2;
        line-height: 25px;
      }

      &_dark {
        & > h5 {
          color: $app_darkmode_text1;
          opacity: 0.65;
          font-weight: 600;
        }
      }
    }

    .ic_w_title {
      position: relative;
      width: 100%;
      display: inline-block;
      margin-top: 15px;

      .ic_only {
        position: relative;
        height: 120px;
        width: 85px;
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        background: url($bear_black_border_one);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &_dark {
          filter: invert(70%);
        }
      }

      .title {
        width: calc(100% - 110px);
        margin-left: 15px;
        display: inline-block;
        vertical-align: middle;

        & > h2 {
          display: inline-block;
          font-weight: 800;
          font-size: 150%;
          text-align: left;
        }

        & > h5 {
          position: relative;
          text-align: left;
          margin-left: 5px;
          margin-top: 5px;
          font-weight: 600;
          color: $app_gray_shade2;
          line-height: 20px;
        }

        &_dark {
          & > h5 {
            color: $app_darkmode_text1;
            opacity: 0.7;
          }
        }
      }
    }

    .early_access {
      position: relative;
      position: relative;
      display: inline-block;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 20px;

      & > div:nth-child(1) {
        display: inline-block;
        position: relative;
        text-align: center;

        & > h5 {
          position: relative;
          font-weight: 600;
          color: $app_gray_shade2;
          line-height: 20px;
          font-size: 85%;
        }
      }

      &_success {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-bottom: 30px;

        & > div:nth-child(1) {
          position: relative;
          display: inline-block;
          position: relative;
          height: 50px;
          width: 55px;
          background: url($celebrate_icon_black);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80% 80%;
        }

        & > h5 {
          font-weight: 700;
          color: $app_gray_shade2;
          line-height: 20px;
          width: 100%;
          text-align: center;
          margin-top: 10px;

          p {
            span {
              font-weight: 600;
            }
          }
        }
      }
    }

    .username_and_password,
    .firstname_and_lastname {
      position: relative;
      display: inline-block;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 25px;

      .tips {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 6px;

        & > div {
          width: calc(50% - 10px);
          display: inline-block;
          text-align: left;
          margin-left: 2px;
          box-sizing: border-box;

          & > h5 {
            display: inline-block;
            text-align: left;
            font-weight: 700;
            font-size: 11px;
            color: $app_gray_shade2;
            max-width: 100%;
          }
        }

        & > div:nth-child(1) {
          vertical-align: top;
        }

        & > div:nth-child(2) {
          margin-left: 14px;
        }
      }

      & > div:nth-child(4) {
        margin-left: 10px;
      }

      & > div:nth-child(2) {
        margin-left: 14px;
      }

      .input_two {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 10px);
        margin-top: 5px;
        box-sizing: border-box;

        & > div:nth-child(1) {
          margin: 0;

          div {
            & > p:nth-child(2) {
              width: calc(100% - 20px) !important;
            }
          }

          label {
            font-weight: 600 !important;
            font-family: 'Manrope' !important;
            font-size: 85% !important;
          }
        }

        input[type='text'],
        input[type='password'] {
          font-size: 80%;
          font-weight: 500;
          font-family: 'Manrope' !important;
          height: 40px;
          letter-spacing: 0.6px !important;
        }

        &_dark {
          label,
          span {
            color: $app_darkmode_text1 !important;
          }

          input[type='text'],
          input[type='password'] {
            background: transparent;
            border: 1px solid $app_darkmode_border1;
            background: $app_darkmode_background3;
            color: $app_darkmode_text1;
          }
        }
      }
    }

    .firstname_and_lastname {
      margin-top: 15px;
    }

    .email {
      position: relative;
      display: inline-block;
      padding: 0 10px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 15px;

      .label {
        width: calc(50% - 8px);
        display: inline-block;
        text-align: left;
        margin-left: 2px;

        & > h5 {
          position: relative;
          font-weight: 600;
        }
      }

      .input_one {
        position: relative;
        width: 100%;
        display: inline-block;
        margin-top: 6px;
        border-radius: 5px;
        box-sizing: border-box;

        & > div:nth-child(1) {
          margin: 0;

          div {
            & > p:nth-child(2) {
              width: calc(100% - 20px) !important;
            }
          }

          label {
            font-weight: 600 !important;
            font-family: 'Manrope' !important;
            font-size: 85% !important;
          }
        }

        input[type='text'],
        input[type='password'] {
          font-size: 80%;
          font-weight: 500;
          font-family: 'Manrope' !important;
          height: 40px;
          letter-spacing: 0.6px !important;
        }

        &_dark {
          label,
          span {
            color: $app_darkmode_text1 !important;
          }

          input[type='text'],
          input[type='password'] {
            background: transparent;
            border: 1px solid $app_darkmode_border1;
            background: $app_darkmode_background3;
            color: $app_darkmode_text1;
          }
        }
      }
    }

    .actions {
      position: relative;
      display: inline-block;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;

      .agree {
        position: relative;
        width: 100%;
        display: inline-block;
        margin-top: 20px;

        label {
          p {
            padding-left: 8px;
            max-width: 85%;
            box-sizing: border-box;
          }

          a {
            text-decoration: none;
            font-weight: 700;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        svg {
          path {
            fill: white;
          }
        }

        &_dark {
          label {
            & > div {
              background: transparent !important;

              svg {
                fill: transparent !important;
                path {
                  fill: transparent !important;
                }
              }
            }
          }

          p {
            color: rgba($color: $app_darkmode_text1, $alpha: 0.7) !important;

            & > a,
            & > span {
              color: $app_darkmode_text1;
            }
          }

          span,
          a {
            color: $app_darkmode_text1 !important;
          }
        }

        &_active_dark {
          label {
            & > div {
              background: $app_blue_shade3 !important;

              svg {
                fill: $app_white_shade3 !important;

                path {
                  fill: $app_white_shade3 !important;
                }
              }
            }
          }

          p {
            color: rgba($color: $app_darkmode_text1, $alpha: 0.7) !important;
          }

          span,
          a {
            color: $app_darkmode_text1 !important;
          }
        }
      }

      .spinner_wrap {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 8px;

        .raw {
          position: relative;
          display: inline-block;
          text-align: center;
        }

        &_dark {
          .raw {
            svg {
              circle {
                stroke: $app_white_light;
              }
            }
          }
        }
      }

      .status {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 5px;

        & > div:nth-child(1) {
          display: inline;
          text-align: center;

          & > h5 {
            font-weight: 700;
          }
        }
      }

      .confirm {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;

        &_button {
          position: relative;
          width: 100%;
          height: 40px;
        }

        &_w_status {
          margin-top: 12px;
        }

        &_wno_status {
          margin-top: 25px;
        }
      }

      .redirect_login {
        position: relative;
        width: 100%;
        margin-top: 20px;
        display: inline-block;
        text-align: center;

        & > h5 {
          font-weight: 700;
          line-height: 20px;
          display: inline-block;
          text-align: center;

          p {
            position: relative;
            display: inline-block;
            text-align: center;

            a {
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  &_dark {
    background: $app_darkmode_background1;

    .actual {
      background: $app_darkmode_background_header1;
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
    }
  }
}

@media screen and (max-width: 900px) {
  .sign_upv2 {
    .actual {
      border: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      box-shadow: none;
      background: transparent;
    }

    &_dark {
      .actual {
        border: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: transparent;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .sign_upv2 {
    .actual {
      width: 95%;
      margin: 0;
      margin-left: 2.5%;
    }
  }
}
