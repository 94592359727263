@import '../theme/color.scss';

.darkmode_text {
  * {
    color: $app_darkmode_text1;
  }
}

.darkmode_background {
  background: $app_darkmode_background1;
}

#root {
  box-sizing: border-box;
}

.pages {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  // border: 2px solid red;

  &_padding {
    box-sizing: border-box;
    // padding-top: 40px;
    // border: 2px solid red;
  }
}
