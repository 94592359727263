@import '../../../theme/commons.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/color.scss';

.docs_tasks {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  display: inline-block;

  .create_task_label {
    width: 100%;
    text-align: left;

    & > h2 {
      position: relative;
      display: inline-block;
      max-width: 100%;
      font-weight: 800;
      font-size: 200%;
    }
  }

  .for_questions {
    position: relative;
    width: 100%;
    text-align: left;
    margin-top: 60px;

    & > h5 {
      font-weight: 600;
      display: inline-block;
      position: relative;

      & > p {
        font-weight: 600;
        position: relative;
        display: inline-block;
        color: $app_gray_shade2;

        span {
          font-weight: 800;
        }
      }
    }

    &_dark {
      & > h5 {
        & > p {
          color: rgba($color: $app_darkmode_text1, $alpha: 0.7);
          font-weight: 500;

          a,
          span {
            font-weight: 600;
            color: $app_darkmode_text1;
          }
        }
      }
    }
  }

  .title {
    position: relative;
    width: 100%;
    text-align: left;
    margin-top: 25px;

    & > h3 {
      font-weight: 700;
      display: inline-block;
      text-align: left;
    }
  }

  .list_title_w_desc {
    position: relative;
    width: 100%;
    margin-top: 20px;

    & > ol {
      position: relative;
      width: 100%;
      display: inline-block;
      list-style-type: none;
      list-style: none;

      & > li {
        width: 100%;
        display: inline-block;

        & > h5 {
          position: relative;
          line-height: 20px;
          display: inline-block;
          width: 100%;

          & > p,
          & > span {
            display: inline-block;
            vertical-align: top;
            font-weight: 500;
          }

          & > p {
            line-height: 22px;
            margin-left: 5px;
            width: calc(100% - 90px);

            & > span {
              font-weight: 700;
            }
          }

          & > span:nth-child(1) {
            font-weight: 800;
            line-height: 26px;
            margin-right: 5px;
            position: relative;
            width: 70px;
            margin-top: -5px;
          }
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 10px;
      }
    }
  }

  .message {
    width: 100%;
    text-align: left;
    margin-top: 10px;

    & > p:not(:nth-child(1)) {
      margin-top: 10px;
    }

    & > p {
      line-height: 27px;

      span,
      a {
        font-weight: 800;
        text-decoration: none;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .create_task_link_wrap_tip {
      position: absolute;
      display: none;
      z-index: calc(#{$high_front} + 1);
      top: calc(100% + 30px);
      bottom: unset;
      left: unset;
      right: 0;

      h5 {
        font-size: 80%;
        white-space: nowrap;
      }
    }

    &:hover {
      .create_task_link_wrap_tip {
        display: block;
      }
    }

    .create_task_button {
      position: relative;
      width: 100%;
      margin-top: 20px;

      & > div:nth-child(1) {
        position: relative;
        height: 35px;
        width: 35px;
        border-radius: 4px;
        background: $app_dominant_purple;
        box-shadow: 1px 1px 28px -5px rgba(97, 60, 231, 0.75);
        -webkit-box-shadow: 1px 1px 28px -5px rgba(97, 60, 231, 0.75);
        -moz-box-shadow: 1px 1px 28px -5px rgba(97, 60, 231, 0.75);
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px;
        cursor: pointer;

        & > div {
          position: relative;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          top: 0;
          left: 0;
          background: url($plus_sign_white);
          background-position: center;
          background-size: 35%;
          background-repeat: no-repeat;
        }
      }
    }

    .create_tag_button {
      position: relative;
      width: 100%;
      margin-top: 20px;

      & > div:nth-child(1) {
        position: relative;
        border-radius: 4px;
        display: inline-block;
        vertical-align: middle;
        padding: 4px 12px;
        border: 1px solid $app_white_shade3;
        margin: 0 10px;
        cursor: pointer;

        h5,
        .create_tag_button_ic {
          display: inline-block;
        }

        h5 {
          font-weight: 700;
        }

        .create_tag_button_ic {
          height: 15px;
          width: 15px;
          background: url($tags_icon);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          margin-right: 6px;
          margin-top: -2px;
          vertical-align: middle;
        }
      }
    }
  }
}
