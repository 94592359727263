@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.user_avatar {
  position: relative;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;

  &_no_dp {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
  }

  &_w_dp {
    visibility: visible;
    opacity: 1;
    overflow: unset;
  }

  & > div:nth-child(1) {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: block;
    text-align: center;
    background: rgba($color: $app_black_shade4, $alpha: 1);
    overflow: hidden;
    font-size: 100%;

    & > h5 {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-weight: 600;
      font-size: 80%;
      line-height: 36px;
      color: $app_white_absolute;
    }
  }

  &:hover {
    .for_hover {
      display: inline-block;
    }
  }

  .for_hover {
    position: absolute;
    top: calc(100% + 2px);
    margin-left: -60%;
    padding: 4px 8px;
    border-radius: 4px;
    background: $app_black_shade1;
    z-index: calc(#{$normal_front} + 2);
    display: none;

    & > h5 {
      min-width: 50px;
      max-width: 200px;
      color: $app_white_absolute;
      font-weight: 700;
      white-space: nowrap;
    }
  }

  .img {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}

@media screen and (max-width: 600px) {
  .user_avatar {
    height: 32px;
    width: 32px;

    & > div:nth-child(1) {
      & > h5 {
        line-height: 32px;
      }
    }
  }
}
