@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';

.comments_or_activities {
  position: relative;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;

  .comments {
    position: relative;
  }

  .activities {
    position: relative;
  }

  .label {
    position: relative;
    margin-bottom: 4px;
    // border: 1px solid red;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(216, 218, 229, 0.5);

    .selected {
      opacity: 1;
    }

    .not_selected {
      opacity: 0.55;
    }

    .select_comments,
    .select_activities {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      & > button {
        padding: 20px 8px;
        box-sizing: border-box;

        & > h5 {
          position: relative;
          font-weight: 700;
          font-size: 105%;
          color: $app_black_shade4;
          letter-spacing: 0.6px;
        }
      }

      &_dark {
        & > button {
          & > h5 {
            color: $app_darkmode_text1;
          }
        }
      }

      &_active {
        border-bottom: 1px solid rgba($color: $app_black_shade3, $alpha: 0.8);

        &_dark {
          border-bottom: 1px solid
            rgba($color: $app_darkmode_text2, $alpha: 0.5);
        }
      }
    }

    .select_activities {
      margin-left: 10px;
    }

    &_dark {
      button {
        &:hover {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
        }
      }

      border-bottom: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
    }
  }
}

@media screen and (min-width: $max_width_fix) {
  .comments_or_activities {
    width: 780px;
    margin-left: calc((100% - 875px - 200px) / 2);
  }
}

@media screen and (max-width: $max_width_fix) {
  .comments_or_activities {
    width: 780px;
    box-sizing: border-box;
    margin-left: calc((100% - 870px - 200px) / 2);
  }
}

@media screen and (max-width: 1200px) {
  .comments_or_activities {
    width: 720px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 1100px) {
  .comments_or_activities {
    width: 695px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .comments_or_activities {
    width: 645px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 990px) {
  .comments_or_activities {
    width: 620px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 949px) {
  .comments_or_activities {
    width: 92%;
    margin-left: 4%;
  }
}
