@import '../../../../theme/color.scss';
@import '../../../../theme/commons.scss';

.comment_mobile {
  position: fixed;
  width: 100vw;
  display: none;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0;
  bottom: 0;
  z-index: calc(#{$high_front} + 3);
  box-sizing: border-box;

  &_ios {
    position: sticky !important;
  }
}

@media screen and (max-width: 900px) {
  .comment_mobile {
    display: inline-block;
    overflow: unset;

    &_ios {
      position: sticky !important;
      padding-bottom: 4px;
    }

    &_raw {
      position: relative;
      display: inline-block;
      min-height: 60px;
      width: 100%;
      left: 0;
      background: $app_white_absolute;
      border: 2px solid $app_white_shade3;
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &_dark {
        border: 2.2px solid rgba($color: $app_darkmode_text1, $alpha: 0.05);
        border-bottom: none;
        border-bottom: none;
        border-left: none;
        border-right: none;
        background: $app_darkmode_background_header1;
      }

      .close {
        position: absolute;
        top: 5px;
        right: 12px;

        & > button {
          background: transparent;

          &:hover,
          &:active {
            background: transparent;
          }

          svg {
            height: 14px;
            width: 14px;
          }
        }

        &_dark {
          & > button {
            svg {
              fill: $app_darkmode_text1;

              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }

      .island {
        position: relative;
        width: 100%;
        margin-top: -4px;
        display: inline-block;
        text-align: center;

        & > div:nth-child(1) {
          position: relative;
          width: 55px;
          height: 4px;
          display: inline-block;
          border-radius: 20px;
          background: $app_white_shade3;
        }

        &_dark {
          & > div:nth-child(1) {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.1);
          }
        }

        &_editing {
          padding: 10px 2px;
          box-sizing: border-box;
        }
      }

      .content {
        position: relative;
        padding: 0 8px;
        padding-bottom: 8px;
        box-sizing: border-box;

        & > div:nth-child(1) {
          border: none;
        }

        .cta_write {
          position: relative;
          width: 100%;
          margin-top: 4px;

          & > button {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            padding: 8px 10px;

            &:hover,
            &:active {
              background: transparent !important;
            }

            & > h4 {
              font-weight: 600;
            }
          }
        }
      }

      &_editing {
        min-height: 80px;
      }
    }
  }
}
