@import '../../../../theme/color.scss';
@import '../../../../theme/assets.scss';
@import '../../../../theme/commons.scss';

.comment_entity {
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: left;
  padding: 10px 6px;
  padding-bottom: 8px;
  box-sizing: border-box;

  .comment_avatar,
  .content {
    position: relative;
    display: inline-block;
  }

  .comment_avatar {
    border-radius: 50%;
    vertical-align: top;
    margin-left: 5px;
  }

  .content {
    margin-left: 12px;
    vertical-align: top;
    width: calc(100% - 70px);
    // border: 2px solid red;
    box-sizing: border-box;

    .name {
      position: relative;
      margin-top: 2px;
      width: 100%;

      & > h5:nth-child(1) {
        position: relative;
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
      }

      & > div:nth-child(2),
      & > div:nth-child(3) {
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .created {
      position: relative;
      font-weight: 600;
      margin-top: 2px;
      display: inline-block;
      vertical-align: middle;
      font-size: 80%;

      p,
      span {
        font-weight: 600;
      }
    }

    .edited {
      position: relative;
      display: inline-block;
      margin-top: 2px;
      vertical-align: middle;
      margin-left: 5px;
      font-weight: 700;
    }

    & > textarea {
      min-height: 35px;
      resize: none;
      font-size: 85%;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    & > textarea:nth-child(4) {
      position: relative;
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      background: transparent;
      box-sizing: border-box;
      margin-left: -2px;
    }

    & > textarea:nth-child(5) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: scroll;
      resize: none;
      visibility: hidden;
      padding: 0 4px;
    }

    .no_edit {
      border: none;
      outline: none;
    }

    .is_editing {
      outline: unset;
      border-radius: 3px;
      padding: 0 4px;
      border: 1.5px solid rgba($color: $app_gray_shade4, $alpha: 0.7);
    }

    .actions {
      position: relative;
      width: 100%;
      margin-top: 5px;
      display: inline-block;
      height: 45px;

      &_actual {
        position: absolute;
        top: 5px;
        right: 0;
        display: inline-block;

        .cancel,
        .save {
          display: inline-block;
          border: 1.5px solid $app_white_shade3;
          border-radius: 4px;
          transition: all 0.2s ease-out;

          &:hover {
            background: rgba($color: $app_white_light, $alpha: 0.6);
          }

          button {
            padding: 5px 10px;

            & > h4,
            & > h5 {
              font-weight: 600;
            }
          }

          &_dark {
            border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);

            &:hover {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }

            button {
              & > h4,
              & > h5 {
                color: $app_darkmode_text1;
              }
            }
          }
        }

        .save {
          margin-left: 10px;
        }
      }
    }

    .deleted {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 10px;

      & > div {
        position: relative;
        display: inline-block;
        border: 1px solid $app_white_shade3;
        padding: 10px 15px;
        text-align: center;
        border-radius: 5px;

        & > h5 {
          font-weight: 700;
        }
      }
    }
  }

  .comment_input {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
  }

  .spinner_wrap {
    position: relative;
    display: inline-block;
    text-align: left;
    margin-left: 52px;
    margin-top: 10px;

    .raw {
      position: relative;
      display: inline-block;
      text-align: center;
    }

    &_dark {
      svg {
        circle {
          stroke: $app_white_light;
        }
      }
    }
  }
}
