@import '../../theme/color.scss';

.button_click {
  position: relative;

  button {
    font-size: 80%;
    border: none;
  }

  &_animate {
    button {
      border: 2px solid transparent;
      box-sizing: border-box;
      transition: border 0.1s ease-out;

      &:active {
        border: 2px solid;
        border-radius: 2px;
        border-color: rgba($color: $app_blue_shade2, $alpha: 0.5);
        border-radius: 4px;
      }
    }
  }
}
