@import '../../../theme/commons.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/color.scss';

.task_comments {
  position: relative;

  &_default {
    position: relative;
    margin-top: 10px;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 40px;
  }

  &_raw {
    position: relative;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;

    .comments_load_more {
      position: relative;
      width: 100%;
      display: inline-block;
      margin: 6px 0;
      text-align: center;

      & > div:nth-child(1) {
        display: inline-block;
        text-align: center;

        button {
          & > div:nth-child(1) {
            display: inline-block;

            & > h4,
            & > div:nth-child(2) {
              position: relative;
              display: inline-block;
              vertical-align: middle;
            }

            & > h5,
            & > h4 {
              text-align: center;
              font-weight: 700;
              line-height: 18px;
            }

            & > div:nth-child(2) {
              height: 25px;
              width: 25px;
              box-sizing: border-box;
              margin-left: 3px;
              background: url($arrow_down_black_2);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 90% 90%;
            }
          }
        }
      }
    }

    .comments_blocked {
      position: relative;
      width: 100%;
      margin: 10px 0;
      margin-top: 16px;

      & > h5 {
        position: relative;
        margin-right: 10px;
        font-weight: 700;
        font-size: 92%;
      }

      & > p {
        position: relative;
        font-size: 85%;
        opacity: 0.85;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
      }
    }

    .comments_empty {
      position: relative;
      width: 100%;
      margin: 10px 0;
      margin-top: 24px;

      & > h5 {
        margin-right: 10px;
        font-weight: 700;
        font-size: 92%;
      }
    }

    .cta_to_signup {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 10px;

      .signup_button {
        position: relative;
        box-sizing: border-box;

        & > h5,
        & > h4 {
          box-sizing: border-box;
          padding: 4px 10px;
          font-weight: 700;
        }

        .cover {
          a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            text-decoration: none;
          }
        }

        &_dark {
          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
          }
        }
      }
    }

    .status {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: left;
      margin-top: 3px;
      box-sizing: border-box;
      padding-left: 46px;

      & > h5 {
        font-weight: 700;
        text-align: left;
        display: inline-block;
        line-height: 18px;
        padding-left: 5px;
        box-sizing: border-box;
      }
    }

    .list {
      position: relative;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      padding: 6px 5px;
      margin-bottom: 10px;
      margin-top: 15px;

      & > ul {
        position: relative;
        width: 100%;
        list-style: none;
        list-style-type: none;

        & > li {
          position: relative;
          width: 100%;
          display: inline-block;
          text-align: center;
          margin-top: 2px;
          border-top: 2px solid rgba($color: $app_white_shade3, $alpha: 0.35);
          padding-top: 20px;

          .content {
            margin-left: 12px;
            box-sizing: border-box;
            text-align: left;
            margin-top: -4px;

            &_default {
              width: calc(100% - 60px);
            }

            & > h5 {
              font-weight: 600;
              text-align: left;
              margin-top: 6px;
              line-height: 18px;
            }

            & > h5:nth-child(3) {
              max-width: 100%;
              overflow-wrap: break-word;
            }

            & > textarea:nth-child(3) {
              position: relative;
              height: auto;
              width: 100%;
              margin-top: 5px;
              display: inline-block;
              border: none;
              background: transparent;
              outline: none;
              box-shadow: none;
              resize: none;
              font-size: 85%;
              font-weight: 600;
              line-height: 20px;
              min-height: 80px;
              max-height: 220px;
              overflow: visible;
            }

            & > h5:nth-child(2) {
              font-weight: 700;
              color: $app_gray_shade2;
              text-align: left;
              line-height: 20px;
              margin-top: 0;

              p,
              span {
                font-size: 100%;
                font-weight: 700;
                color: $app_gray_shade2;
              }
            }

            & > h5:nth-child(1) {
              position: relative;
              text-align: left;
              font-weight: 700;
              margin-right: 12px;
              font-size: 12px;
              max-width: 100%;
              max-height: 17px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:hover {
            .comment_opts {
              display: inline-block;
              opacity: 1;
              visibility: visible;
            }
          }

          .not_show {
            opacity: 0 !important;
            visibility: hidden !important;
          }

          .comment_opts {
            height: 25px;
            width: 30px;
            margin-left: 6px;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            vertical-align: top;

            & > ul {
              list-style-type: none;
              list-style: none;
              height: 100%;
              width: 100%;
              position: relative;
              box-sizing: border-box;
              border-radius: 4px;
              cursor: pointer;

              & > li {
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background: $app_black_shade1;
                margin-left: 3px;
              }

              & > li:not(:nth-child(1)) {
                margin-left: 4px;
              }
            }
          }

          .comment_more {
            position: absolute;
            right: 10px;
            top: 27px;
            border: 1.5px solid $app_white_shade3;
            box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.5);
            -webkit-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.5);
            -moz-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.5);
            padding: 6px 13px;
            background: $app_white_absolute;
            border-radius: 4px;
            display: inline-block;
            z-index: $high_front;

            & > ul {
              position: relative;
              display: inline-block;
              text-align: center;
              list-style-type: none;
              list-style: none;

              & > li {
                padding: 2px 5px;
                cursor: pointer;

                &:hover {
                  & > h5 {
                    color: $app_dominant_purple;
                  }
                }

                & > h5 {
                  font-weight: 700;
                }
              }
            }
          }
        }

        & > li:nth-child(1) {
          border-top: none;
        }

        & > li:not(:nth-child(1)) {
          margin-top: 12px;
        }
      }

      &_dark {
        & > ul {
          & > li:not(:nth-child(1)) {
            border-top: 1px solid
              rgba($color: $app_darkmode_text1, $alpha: 0.05);
            border-width: 2px;
          }
        }
      }
    }

    .new_comment {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding: 2px 4px;
      margin-top: 16px;
      padding-right: 0;
      // border: 2px solid red;

      &_avatar {
        position: relative;
        height: 35px;
        width: 35px;
        display: inline-block;
        margin-top: 8px;
        margin-left: 4px;
      }

      &_input_w_avatar {
        width: calc(100% - 45px);

        &_raw {
          border-radius: 50%;
        }
      }

      &_input_no_avatar {
        width: 100%;
      }

      &_input {
        position: relative;
        margin-left: 6px;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        padding: 0;
        overflow: visible;
      }
    }

    .actions {
      position: relative;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      padding: 2px 5px;
      transition: height, opacity 0.1s ease-out;
      height: 45px;

      &_hidden {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
      }

      &_shown {
        visibility: visible;
        overflow: visible;
        opacity: 1;
      }

      .spinner_wrap {
        position: absolute;
        width: 100%;
        top: 6px;
        right: 8px;
        display: inline-block;
        height: 30px;
        width: 30px;

        .raw {
          position: relative;
          display: inline-block;

          &_spin {
            position: relative;
            height: 30px;
            width: 30px;
          }
        }
      }

      .submit {
        position: relative;
        display: inline-block;
        text-align: center;
        // box-shadow: 1px 1px 18px -5px rgba(97, 60, 231, 0.75);
        // -webkit-box-shadow: 1px 1px 18px -5px rgba(97, 60, 231, 0.75);
        // -moz-box-shadow: 1px 1px 18px -5px rgba(97, 60, 231, 0.75);
        // background: $app_dominant_purple;
        padding: 6px 4px;
        padding-top: 10px;
        border-radius: 4px;
        transition: opacity 0.1s ease-out;

        &_hover {
          position: absolute;
          display: none;
          h5 {
            font-size: 102%;
          }
        }

        svg {
          height: 22px;
          width: 22px;
          fill: $app_black_shade1;

          path {
            fill: $app_black_shade1;
          }
        }

        & > h5,
        & > h4 {
          font-weight: 700;
          line-height: 20px;
          color: $app_white_absolute;
          text-align: center;
          line-height: 32px;
        }

        &_wrap {
          position: absolute;
          display: inline-block;
          vertical-align: middle;
          padding: 0;
          right: 5px;
          height: 32px;

          &:hover {
            .submit_hover {
              display: block;
              top: calc(100% + 2px);
            }
          }

          &_dark {
            svg {
              fill: $app_white_shade7;

              path {
                fill: $app_white_shade7;
              }
            }
          }
        }

        &_disable {
          cursor: not-allowed;
          opacity: 0.7;

          &_dark {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 949px) {
  .task_comments {
    &_raw {
      margin-right: 0;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 900px) {
  .task_comments {
    &_raw {
      .cta_to_signup {
        .signup_button {
          padding: 0;
          margin: 0;

          & > h5,
          & > h4 {
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            font-weight: 700;
          }
        }
      }

      .new_comment {
        width: 0;
        height: 0;
        display: none;
      }

      .list {
        & > ul {
          & > li {
            .content {
              & > textarea:nth-child(3) {
                min-height: 100px;
              }
            }

            .comment_opts {
              opacity: 1;
              visibility: visible;

              & > ul {
                border: none;
              }
            }
          }

          & > li:last-child {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
