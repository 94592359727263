$app_white_absolute: #fff;
$app_black_shade1: #323333;
$app_black_shade2: #636363;
$app_black_shade3: #696f8c;
$app_black_shade4: #474d66;
$app_black_shade5: #181d23;
$app_white_light: #f2f2f2;
$app_white_light2: #f0f0f0;
$app_white_shade1: #f3f2fe;
$app_white_shade2: #dfe2e9;
$app_white_shade3: #e0e0e0;
$app_white_shade4: #fafbff;
$app_white_shade5: #e6e8f0;
$app_white_shade6: #edeff5;
$app_white_shade7: #d8dae5;
$app_white_shade8: #f4f6fa;
$app_gray_shade1: #f5f0f0;
$app_gray_shade2: #6e6b6b;
$app_gray_shade3: hsla(240, 8%, 93%, 1);
$app_gray_shade4: #aeaeae;
$app_gray_shade5: #f9fafc;
$app_gray_shade6: #8f95b2;
$app_gray_shade7: #6a708a;
$app_dominant_purple: #613ce7;
$app_purple_shade1: #4464e4;
$app_purple_shade2: #9863ec;
$app_purple_shade3: #8371f3;
$app_purple_shade4: #4630b8;
$app_blue_shade1: #e5ebff;
$app_blue_shade2: #7c93e3;
$app_blue_shade3: #3366fe;
$app_dominant_purple_bg: #ece7fe;
$app_red_shade1: #f53250;
$app_red_shade2: #fcaeaf;
$app_red_shade3: #d14343;
$app_green_shade1: #9acc8a;
$app_green_shade2: #d8f0d1;
$app_green_shade3: #1eccad;
$app_yellow_shade1: #ffdaae;
$app_brown_shade1: rgba(252, 242, 218, 1);
$app_brown_shade2: rgba(143, 117, 55, 1);
$app_button_background: #f8f7fd;
$app_low_prio_color: #1eccad;
$app_medium_prio_color: #e37400;
$app_high_prio_color: #df3e4a;
$app_blue_shade1: #101840;

$app_darkmode_background_header1: #1f252d;
$app_darkmode_background1: #1d232a;
$app_darkmode_background2: #141a20;
$app_darkmode_background3: #222a34;
$app_darkmode_background4: #29313b;
$app_darkmode_background5: #374250;
$app_darkmode_checkbox_background1: #2d3744;
$app_darkmode_border1: #353f4e;
$app_darkmode_text1: #c3d0e5;
$app_darkmode_text2: #9fa6b3;
