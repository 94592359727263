@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/assets.scss';

.image_expand {
  position: absolute;
  top: 0;
  height: 0;
  z-index: $high_front;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > img {
    position: relative;
    height: 98%;
    max-width: 92%;
    object-fit: contain;
    object-position: center;
    display: inline-block;
    border-radius: 8px !important;
  }

  .close {
    position: absolute;
    right: 32px;
    top: 20px;

    & > button {
      background: rgba($color: $app_black_shade1, $alpha: 0.8);
      position: relative;

      &:hover,
      &:active {
        background: rgba($color: $app_black_shade1, $alpha: 0.5);
      }

      svg {
        height: 16px;
        width: 16px;
        fill: #fff;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .image_expand {
    & > img {
      height: unset;
      width: 100%;
    }

    .close {
      position: absolute;
      right: 16px;
      top: 20px;
    }
  }
}
