@import '../../../../theme/color.scss';
@import '../../../../theme/commons.scss';
@import '../../../../theme/scroll.scss';

.space_list {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin: 0;

  .selected {
    position: relative;

    & > button {
      padding: 0;
      margin: 0;
      position: relative;
      display: inline-block;
      overflow: hidden;
      text-align: center;
      vertical-align: middle;
      max-width: 200px;
      min-width: 40px;

      & > h5 {
        position: relative;
        font-weight: 600;
        font-size: 95%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 8px;
        box-sizing: border-box;
        max-width: 200px;
        padding-left: 2px;
        color: $app_black_shade1;
      }
    }

    &_dark {
      & > button {
        background: transparent;
        color: $app_darkmode_text1;

        &:hover {
          background: transparent;
          opacity: 0.9;
        }

        & > h5 {
          color: $app_darkmode_text1;
        }
      }
    }
  }

  .list {
    position: absolute;
    display: inline-block;
    top: calc(100% + 4px);
    left: -2px;
    padding: 0 2px;
    min-width: 195px;
    background: $app_white_absolute;
    border: 1.5px solid $app_white_shade3;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    z-index: calc(#{$high_front} + 1);
    border-radius: 4px;
    min-height: 35px;
    max-height: 160px;
    overflow: hidden;
    overflow-y: scroll;
    padding-left: 4px;

    @include custom_scroll;

    & > ul {
      position: relative;
      width: 100%;
      display: inline-block;
      list-style: none;
      list-style-type: none;

      & > li {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: left;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 4px;
        box-sizing: border-box;

        & > h5 {
          position: relative;
          font-weight: 700;
          font-size: 80%;
          line-height: 20px;
        }

        &:hover {
          background: $app_white_shade4;
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .space_list {
    margin: 10px 0;
    margin-left: -4px;

    .selected {
      & > button {
        width: 100%;
        max-width: 100%;
        text-align: left;

        & > h5 {
          width: calc(100% - 50px);
          max-width: unset;
          text-align: left;
        }
      }
    }
  }
}
