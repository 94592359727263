@import '../../../theme/color.scss';

.terms_of_service_wrap {
  position: relative;
  height: 100%;
  width: 95%;
  box-sizing: border-box;
  display: inline-block;
  padding-bottom: 40px;

  div,
  p,
  li {
    text-align: justify !important;
  }

  .label {
    margin-top: 5px;
    margin-bottom: 25px;
    position: relative;
    width: 100%;

    & > h2 {
      position: relative;
      display: inline-block;
      font-weight: 800;
      font-size: 210%;
      line-height: 30px;
      text-align: left;
    }
  }

  .date_updated {
    position: relative;
    margin: 5px 0;
    width: 100%;

    & > p {
      span {
        font-weight: 600;
      }
    }
  }

  .label_sub_one {
    position: relative;
    margin-top: 15px;

    & > h4 {
      position: relative;
      display: inline-block;
      font-weight: 700;
    }
  }

  .points {
    position: relative;
    width: calc(100% - 30px);
    padding-left: 30px;
    margin-top: 15px;
    text-align: justify !important;

    & > li {
      position: relative;
      max-width: 100%;
      line-height: 28px;
    }
  }

  .reg_mark {
    & > p {
      span {
        font-weight: 700;
      }

      a {
        border: none;
        font-weight: 700;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      line-height: 25px;
    }
  }

  .label_section {
    position: relative;
    margin-top: 15px;

    & > h3 {
      position: relative;
      display: inline-block;
      font-weight: 600;
    }
  }

  .para {
    position: relative;
    width: 100%;

    & > p {
      width: 100%;
      overflow-wrap: break-word;
      text-align: left;
      font-size: 95%;
      font-weight: 500;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 680px) {
  .terms_of_service_wrap {
    width: 100%;
    margin-left: 0;
    overflow-wrap: break-word;
  }
}
