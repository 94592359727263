.ql_youtube_placeholder {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  box-sizing: border-box;

  img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    border-image: none;
    object-fit: contain;
    box-sizing: border-box;
  }
}

.ql_youtube_play {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    position: relative;
    height: 50px;
    width: 150px;
    padding: 10px 15px;
    background: url('data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAEAAAAAtCAYAAAAeA21aAAACXUlEQVR4Ae3aA4xcURSA4VfbVsy6caMyqG3btuPUtm27DWpzbds2Tv9b2zPzdt89ybfeuXn/eOYaUqfOzxlGMdREE7RDf0zGUqzFHpzCVdzGYzjBE34IQSRikYDk99KR+V76Zz9PQCwiEQI/eMEJT3AHV3EKe7EOyzAFA9AeTVELxX91fD866IoYimN4jUhkQAqITETBGScxClV+L4BhdEcQpJCJwMCfBzCM6ZBCbsn3AxhGF4hFDPwygGGUhgvEIvxR+fMA3SEWM/TzAFsgFnPk8wAPIBbjLDVqFFcBqvFNKMRiEgjQQAVo+uFBjsXkE6ClCtBJ/cCKCNBPBRhr84WKFjVrgJkqwDKbLzR+vMjChSIlS5otwCoVYLPNF1qyRNSIk5NIx45mCrBPBThm84UWL5Yv5soVkSZNzBDgvApwze4B1GRliWzaJFKrliMD3FYB7jkkwIeJiRGZPl2kVClHBHimArx0aIAP4+4u0q2bvQO4qQDupgjwYW7eFGnRwl4BfFUAX1MFUJOTI7Jjh0jdurYOEKQCBJkuwIeJjxeZN0+kYkVbBQhTAYJNG+DDnDwpUqyYLQKE60uAvg3Q9wL6cYDjHwnOmCFSurRDHgnq5wL62aB+PUC/ImTp1wT1q8L6fQH9zpB+b9Cy7w7r/QF6h4jeI6R3iel9gr/YKXocTohCFqSAyEIMXHAKo3+2U/TnypYthhpohNbojfFYgBXYhsM4hxu4jSd4DTd4IwAhCEfUe7GIfy/2s5+HIwQB8IYbXuMJ7uAGzuEItmMlFmIC+qINGqMmiv3q+N4ASk8PomBg8rgAAAAASUVORK5CYII=');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    box-sizing: border-box;
  }
}

.ql_youtube_redirect_link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
}
