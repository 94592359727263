@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.init_user_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;

  .logo {
    position: relative;
  }

  &_dark {
    background: $app_darkmode_background1;
  }
}
