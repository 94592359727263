@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';
@import '../../../theme/task.scss';
@import '../../../lib/formats/YoutubeBlot.scss';

.docs_formats {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  display: inline-block;

  .label {
    width: 100%;
    text-align: left;

    & > h2 {
      position: relative;
      display: inline-block;
      max-width: 100%;
      font-weight: 800;
      font-size: 210%;
    }
  }

  .title {
    position: relative;
    width: 100%;
    text-align: left;
    margin-top: 25px;

    & > h3 {
      font-weight: 700;
      display: inline-block;
      text-align: left;
    }
  }

  .message {
    width: 100%;
    text-align: left;
    margin-top: 10px;

    & > p:not(:nth-child(1)) {
      margin-top: 10px;
    }

    & > p {
      line-height: 27px;

      span,
      a {
        font-weight: 800;
        text-decoration: none;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .task_description_only {
    margin-top: 20px;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    text-align: left;

    & > ul {
      list-style-type: none;
    }

    & > ol {
      margin-left: 15px;
    }

    @include task_description;

    &_dark {
      @include task_description_dark;
    }
  }

  .youtube {
    position: relative;
    width: 100%;
    display: inline-block;

    & > div:nth-child(1) {
      height: auto;
      width: 100%;

      & > img {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        border: none;
        box-shadow: none;
        border-image: none;
        object-fit: contain;
        box-sizing: border-box;
      }
    }

    .cover {
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-decoration: none;
      }
    }
  }

  .resources {
    position: relative;
    width: 100%;
    text-align: left;
    margin-top: 30px;

    & > h5 {
      font-weight: 600;
      display: inline-block;
      position: relative;
      max-width: 90%;

      & > p {
        font-weight: 600;
        position: relative;
        display: inline-block;
        color: $app_gray_shade2;

        a {
          font-weight: 800;
          text-decoration: none;
        }

        & > a:nth-child(2) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &_dark {
      & > h5 {
        & > p {
          color: rgba($color: $app_darkmode_text1, $alpha: 0.7);
          font-weight: 500;

          a,
          span {
            font-weight: 600;
            color: $app_darkmode_text1;
          }
        }
      }
    }
  }
}
