@import '../../../theme/color.scss';
@import '../../../theme/commons.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/scroll.scss';

.create_task_v2 {
  position: relative;
  width: 100%;
  height: 100%;
  background: $app_white_absolute;
  box-sizing: border-box;
  // height: 0;
  // width: 0;
  // margin-top: 120px;
  // padding-bottom: 30px;
  // border: 5px solid blue;
  // display: none;

  &_blur {
    filter: blur(4px);
  }

  &_content {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 0;

    .actions_mobile {
      position: absolute;
      display: none;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .expand {
      position: relative;
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: 20px;
      margin-right: 2px;
      box-sizing: border-box;
      // overflow: hidden;

      &_tip {
        top: calc(50% + 20px);
        left: -50px;
        min-width: 90px;
        display: none;
      }

      &_button {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        // background: $app_white_shade5;

        button {
          position: relative;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          & > div:nth-child(1) {
            position: relative;
            display: inline-block;
            padding: 4px 2px;

            svg {
              height: 18px;
              width: 18px;
              fill: $app_gray_shade2;

              path {
                fill: $app_gray_shade2;
              }
            }

            transition: transform 0.1s ease;
          }

          &:hover {
            .expand_tip {
              display: block;
              font-size: 100%;

              h5,
              h4 {
                font-size: 112%;
              }
            }
          }
        }

        &_w_prop {
          transform: rotate(90deg);
        }

        &_no_prop {
          transform: rotate(-90deg);
        }
      }

      &_dark {
        background: $app_darkmode_background5;

        & > div:nth-child(1) {
          button {
            svg {
              fill: $app_darkmode_text1;

              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }

    .spaces {
      position: relative;

      &_desktop {
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
      }
    }

    .first,
    .second {
      height: 100%;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
    }

    .first {
      // border: 2px solid red;
      box-sizing: border-box;

      &_w_prop {
        width: calc(100% - 335px);
        margin-left: 8px;
      }

      &_no_prop {
        width: calc(100% - 40px);
        margin: 0;
        margin-left: 8px;
      }

      &_maximize {
        width: calc(100% - 40px);
        margin-left: 25px;
        padding-top: 46px;
      }

      &_dark {
        background: rgba($color: $app_darkmode_background1, $alpha: 1);
      }

      .error_input {
        position: absolute;
        padding: 10px 0px;
        right: 0;
        top: 0;
        border-radius: 5px;
        background: $app_red_shade1;
        z-index: $normal_front;
        box-shadow: 1px 1px 28px -5px rgba(245, 50, 80, 0.4);
        -webkit-box-shadow: 1px 1px 28px -5px rgba(245, 50, 80, 0.4);
        -moz-box-shadow: 1px 1px 28px -5px rgba(245, 50, 80, 0.4);

        &_msg {
          position: relative;
        }

        &_no_msg {
          display: none;
          height: 0;
          width: 0;
        }

        .msg {
          position: relative;
          max-width: 290px;
          padding: 0px 8px;
          color: $app_white_absolute;
          font-size: 85%;
          font-weight: 500;
        }

        & > span:nth-child(1) {
          position: absolute;
          top: -10px;
          width: 0;
          height: 0;
          left: 8px;
          background: transparent;

          &::after {
            position: absolute;
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 9px solid $app_red_shade1;
            left: 0;
            top: 1px;
          }

          &::before {
            position: absolute;
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid transparent;
            left: -1px;
            top: 0;
          }
        }
      }

      .info {
        position: relative;
        width: 100%;
        padding: 0 5px;
        margin-top: 25px;
        box-sizing: border-box;
        // border: 1px solid red;

        & > div:nth-child(1) {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &_arrow {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          // border: 2px solid red;
          margin-top: 2px;

          svg {
            height: 14px;
            width: 14px;
            fill: $app_gray_shade2;

            path {
              fill: $app_gray_shade2;
            }
          }

          &_dark {
            svg {
              fill: $app_darkmode_text1;

              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }

        & > div:nth-child(1) {
          position: relative;
          width: 100%;
        }

        .current_state,
        .space,
        .task_id {
          position: relative;
          box-sizing: border-box;
          margin-left: 6px;

          h5 {
            display: inline-block;
            font-weight: 600;
            font-size: 85%;
            color: $app_gray_shade2;
            margin: 0;
          }
        }

        .task_id,
        .current_state {
          & > h5 {
            position: relative;
          }

          &_dark {
            & > h5 {
              color: $app_darkmode_text1;
            }
          }
        }

        .task_id {
          & > h5 {
            margin-left: 5px;
            margin-top: 1px;
          }
        }

        .date {
          position: relative;
          width: 100%;
          margin-top: 4px;

          & > p {
            // border: 2px solid red;
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            vertical-align: middle;
          }

          & > p {
            font-size: 82%;
            text-align: left;
          }

          &_dark {
            & > p {
              color: $app_darkmode_text1;
            }
          }
        }
      }

      .error_input {
        z-index: $high_front;
      }

      .task_title {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        display: inline-block;
        margin-top: 10px;
        max-height: 50px;
        margin-bottom: 5px;
        overflow: hidden;
        padding: 0;
        // border: 1px solid red;
        // do this on mobile screen instead
        // overflow-y: scroll;

        .to_edit {
          position: absolute;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;
          right: 10px;
          top: 10px;
          box-sizing: border-box;
          // display: none;
          // border: 1px solid red;

          &_button {
            position: relative;
            margin-bottom: 6px;

            button {
              padding: 2px 4px;
            }

            svg {
              fill: $app_gray_shade6;
            }
          }
        }

        @include custom_scroll;

        &_placeholder {
          position: relative;
          margin-top: 30px;
          width: 65%;
          height: 10px;
          border-radius: 15px;
          background: $app_gray_shade1;
          animation: opacity-load 2.3s ease-in-out infinite;

          &_dark {
            -moz-box-shadow: 0px 4px 50px $app_black_shade5;
            -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
            box-shadow: 0px 4px 50px $app_black_shade5;
            background: $app_darkmode_background_header1;
          }
        }

        &_input {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          min-width: unset;
          width: calc(100% - 3px);
          height: 40px;
          padding: 4px 10px;
          padding-top: 8px;
          padding-left: 5px;
          border-radius: 4px;
          box-sizing: border-box;
          border: 2px solid transparent;
          transition: border 0.1s ease-out;
          // overflow-x: scroll;
          // border: 1px solid red;
          // border: 2px solid $app_white_shade3;
          border: 2px solid rgba($color: $app_white_shade3, $alpha: 0.6);

          &_hidden {
            position: absolute;
            height: 100%;
            width: 100%;
            font-size: 88%;
            font-weight: 600;
            margin-top: -20px;
            box-sizing: border-box;
            -webkit-box-shadow: none;
            box-shadow: none;
            visibility: hidden;
            resize: none;
            top: 0;
            left: 0;
          }

          & > textarea {
            white-space: nowrap;
            opacity: 0.9;
          }

          & > textarea:nth-child(1) {
            height: 100%;
            width: 100%;
            font-size: 88%;
            font-weight: 600;
            display: inline-block;
            background: transparent;
            overflow-wrap: break-word;
            border: none;
            outline: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            box-sizing: border-box;
            resize: none;
            overflow: hidden;
            overflow-y: hidden;
            // border: 1px solid red !important;
          }

          &_dark {
            border: 2px solid rgba($color: $app_darkmode_text1, $alpha: 0.15);

            & > textarea {
              color: $app_darkmode_text1;
            }
          }

          &_active {
            box-sizing: border-box;
            border: 2px solid $app_white_shade7;

            & > textarea {
              opacity: 1;
            }

            &_dark {
              & > textarea {
                color: $app_darkmode_text1;
              }

              border: 2px solid rgba($color: $app_darkmode_text1, $alpha: 0.5);
            }
          }
        }
      }

      .actions {
        position: absolute;
        bottom: 10px;
        right: 8px;
        display: inline-block;
        padding: 5px 0;

        &_on_expand {
          top: 20px;
          bottom: unset;
        }

        .cancel,
        .delete,
        .confirm {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          padding: 0;

          button {
            padding-top: 3px;
            padding-bottom: 3px;

            & > h5,
            & > h4 {
              font-weight: 700;
            }
          }
        }

        .cancel,
        .delete {
          button {
            &:hover {
              background: transparent;
            }
          }

          &_dark {
            button {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.02);

              &:hover {
                background: rgba($color: $app_darkmode_text1, $alpha: 0.1);
              }
              & > h4 {
                color: $app_darkmode_text1;
              }
            }
          }
        }

        .delete,
        .confirm {
          margin-left: 10px;
        }

        .confirm {
          button {
            position: relative;
            padding-left: 8px;
            padding-right: 8px;
            box-sizing: border-box;
          }

          &_disabled {
            cursor: not-allowed;
            button {
              cursor: not-allowed;
            }
          }
        }
      }

      .task_description_wrap {
        position: relative;
        width: 100%;
        padding-right: 5px;
        box-sizing: border-box;
        height: 70%;
        height: calc(100% - 190px);
        // border: 10px solid red;

        &_maximize {
          height: calc(100% - 170px);
        }
      }
    }

    .spinner_wrap {
      position: relative;
      display: inline-block;
      text-align: center;
      padding: 8px 2px;
      box-sizing: border-box;

      .raw {
        position: relative;
        height: 28px;
        width: 28px;
      }

      &_dark {
        .raw {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }
    }

    .second {
      position: relative;
      vertical-align: top;
      width: 300px;
      border-radius: 5px;
      overflow-y: scroll;
      padding: 16px 18px;
      padding-left: 22px;
      box-sizing: border-box;
      padding-bottom: 30px;
      background: $app_gray_shade5;

      &_mobile_actions {
        display: none;
      }

      &_dark {
        background: rgba($color: $app_darkmode_background2, $alpha: 1);
      }

      @include custom_scroll;

      .property {
        position: relative;
        width: 100%;

        &_placeholder {
          width: 90%;
          position: relative;
          display: inline-block;
          margin-top: 10px;

          & > div:nth-child(1) {
            width: 100%;
            border-radius: 15px;
            height: 10px;
            background: $app_gray_shade1;
            animation: opacity-load 2.3s ease-in-out infinite;
          }

          &_dark {
            & > div:nth-child(1) {
              -moz-box-shadow: 0px 4px 50px $app_black_shade5;
              -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
              box-shadow: 0px 4px 50px $app_black_shade5;
              background: $app_darkmode_background_header1;
            }
          }
        }

        &_label {
          position: relative;
          margin-bottom: 15px;

          & > h4 {
            position: relative;
            font-weight: 800;
          }
        }

        .title {
          position: relative;
          width: 90px;
          box-sizing: border-box;
          margin-top: 5px;

          & > p {
            font-size: 82%;
            font-weight: 600;
            color: $app_gray_shade2;
          }

          &_dark {
            & > p,
            & > h5 {
              color: $app_darkmode_text1;
            }
          }
        }

        .value {
          position: relative;
          display: inline-block;
          width: 100%;
          box-sizing: border-box;
          margin-top: 5px;
        }

        &_priority {
          position: relative;
          width: 100%;

          .priority_icon {
            position: absolute;
            left: 8px;
            top: 7px;
            // border: 1px solid red;
            box-sizing: border-box;
            display: inline-block;
            z-index: $high_front;
          }

          &_opts {
            position: relative;
            width: 100%;
            // border: 1px solid red;
            background: $app_white_absolute;

            select {
              padding-left: 35px;
              box-sizing: border-box;
            }

            &_dark {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.02);

              select {
                background: rgba($color: $app_darkmode_text1, $alpha: 0.02);
                color: $app_darkmode_text1;
                border: none;

                option {
                  color: black !important;
                }
              }
            }
          }
        }

        &_privacy {
          position: relative;
          margin-top: 13px;

          &_tip {
            position: relative;
            width: 100%;
            margin-top: 2px;

            & > p {
              display: inline-block;
              font-size: 75%;
              max-width: 90%;
              overflow-wrap: break-word;
              font-weight: 600;
            }

            &_dark {
              & > p {
                color: $app_darkmode_text1;
              }
            }
          }

          &_value {
            position: relative;
            display: inline-block;

            .choices {
              position: relative;
              width: 100%;

              & > ul {
                position: relative;
                width: 100%;
                list-style: none;
                list-style-type: none;
                display: block;

                & > li {
                  position: relative;
                  display: inline-block;
                  text-align: center;

                  svg {
                    fill: #fff;

                    path,
                    circle {
                      color: #fff;
                      fill: #fff;
                    }
                  }

                  span {
                    font-size: 85%;
                    font-weight: 600;
                  }
                }

                & > li:not(:nth-child(1)) {
                  margin-left: 20px;
                }
              }

              &_dark {
                & > ul {
                  & > li {
                    span {
                      color: $app_darkmode_text1;
                    }
                  }
                }
              }
            }

            .passcode {
              position: relative;
              width: 100%;

              &_disabled {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba($color: $app_white_light, $alpha: 1);
                opacity: 1;
              }

              &_title {
                position: relative;
                width: 100%;
                margin-top: 15px;
                margin-bottom: 4px;

                & > h5 {
                  display: inline-block;
                  vertical-align: top;
                  font-weight: 600;
                  color: $app_gray_shade2;
                }

                & > div {
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  margin-left: 5px;
                  margin-top: -3px;
                  cursor: pointer;

                  svg {
                    fill: $app_gray_shade2;

                    path {
                      fill: $app_gray_shade2;
                    }
                  }

                  .passcode_title_tip {
                    position: absolute;
                    left: -10px;
                    display: none;
                    top: 100%;
                  }

                  &:hover {
                    .passcode_title_tip {
                      display: block;
                    }
                  }
                }

                &_dark {
                  & > h5 {
                    color: $app_darkmode_text1;
                  }

                  & > div {
                    svg {
                      fill: $app_darkmode_text1;
                      path {
                        fill: $app_darkmode_text1;
                      }
                    }
                  }
                }
              }

              &_input {
                position: relative;
                display: inline-block;
                box-sizing: border-box;
                width: 100%;

                &_show {
                  position: absolute;
                  right: 7px;
                  top: 15px;
                  display: inline-block;

                  svg {
                    fill: $app_gray_shade2;

                    path {
                      fill: $app_gray_shade2;
                    }
                  }

                  &_dark {
                    svg {
                      fill: $app_darkmode_text1;

                      path {
                        fill: $app_darkmode_text1;
                      }
                    }
                  }
                }

                &_raw {
                  width: 100%;
                  margin: 0;

                  & > div:nth-child(1) {
                    margin: 0;
                    padding: 0;

                    label {
                      display: none;
                    }
                  }

                  input[type='text'],
                  input[type='password'] {
                    font-size: 75% !important;
                    font-weight: 500 !important;
                    font-family: 'Manrope' !important;
                    height: 30px;
                    width: 100%;
                  }

                  &_dark {
                    input[type='text'],
                    input[type='password'] {
                      background: transparent;
                      border: 1px solid $app_darkmode_border1;
                      background: $app_darkmode_background3;
                      color: $app_darkmode_text1;
                    }
                  }
                }
              }

              &_label {
                position: relative;
                width: 100%;

                span {
                  font-weight: 600;
                }

                svg {
                  fill: #fff;

                  path,
                  circle {
                    color: #fff;
                    fill: #fff;
                  }
                }

                label {
                  span {
                    font-size: 85%;
                  }
                }

                &_dark {
                  label,
                  span {
                    color: $app_darkmode_text1;
                  }
                }
              }

              &_cb {
                position: relative;
                height: 20px;
                width: 20px;
                margin-right: 4px;
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;

                &_raw {
                  position: relative;
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .state {
        &_wrap {
          margin-top: 5px;
        }

        &_opts {
          position: relative;
          width: 100%;
          background: $app_white_absolute;
        }

        &_selected {
          position: relative;
          width: 100%;

          & > h4 {
            position: relative;
            display: inline-block;
            font-size: 82%;
            font-weight: 700;
            line-height: 20px;
            vertical-align: middle;
            margin-right: 10px;
            max-width: calc(100% - 30px);
            overflow-wrap: break-word;
          }
        }

        &_opts {
          &_dark {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.02);

            select {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.02);
              color: $app_darkmode_text1;
              border: none;

              option {
                color: black !important;
              }
            }
          }
        }
      }

      .tags {
        &_wrap {
          margin-top: 15px;

          & > div:nth-child(1) {
            vertical-align: top;
          }
        }

        &_list {
          position: relative;
          width: 100%;

          &_active {
            margin-top: 10px;
            margin-bottom: 12px;
          }

          & > ul {
            position: relative;
            width: calc(100% - 5px);
            display: inline-block;
            list-style: none;
            list-style-type: none;

            & > li {
              position: relative;
              float: left;
              margin-right: 6px;
              box-sizing: border-box;
              overflow-wrap: break-word;
              margin-top: 4px;
              cursor: pointer;
              user-select: none;
              -webkit-user-select: none;
              -moz-user-select: none;

              & > div:nth-child(1) {
                position: relative;
                border-radius: 5px;
                background: $app_gray_shade1;

                & > h5 {
                  font-size: 82%;
                  font-weight: 800;
                  line-height: 22px;
                  display: inline-block;
                  margin-left: 4px;
                  padding: 2px 12px;
                  vertical-align: middle;
                  color: $app_black_shade2;
                  line-height: 22px;
                  box-sizing: border-box;
                }
              }
            }
          }
        }

        &_attach {
          display: inline-block;
          position: relative;
          box-sizing: border-box;

          &_raw {
            background: $app_dominant_purple;
            border-radius: 4px;
            padding: 3px 7px;
            padding-bottom: 5px;
            padding-right: 13px;
            box-shadow: 1px 1px 28px -5px rgba(97, 60, 231, 0.75);
            -webkit-box-shadow: 1px 1px 28px -5px rgba(97, 60, 231, 0.75);
            -moz-box-shadow: 1px 1px 28px -5px rgba(97, 60, 231, 0.75);
          }

          &_ic,
          &_label {
            position: relative;
            display: inline-block;
          }

          &_label {
            line-height: 22px;
            vertical-align: middle;

            & > p {
              font-weight: 700;
              font-size: 98%;
              color: #fff;
            }
          }

          &_ic {
            height: 20px;
            width: 20px;
            box-sizing: border-box;
            vertical-align: middle;
            background: url($plus_sign_white);
            background-position: center;
            background-size: 45% 45%;
            background-repeat: no-repeat;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &_dark {
    border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
    background: $app_darkmode_background1;
  }
}

@media screen and (max-width: 900px) {
  .create_task_v2 {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    box-sizing: border-box;

    &_content {
      .actions_mobile {
        // mobile actions in header format
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        // border: 1px solid red;

        .close {
          position: absolute;
          left: 2px;
          top: 12px;
          display: inline-block;

          &_button {
            position: relative;
            width: 15px;
            height: 25px;
            background: transparent;

            &:hover {
              background: transparent;
              background-color: transparent;
            }
          }

          &_dark {
            button {
              svg {
                fill: $app_darkmode_text1;

                path {
                  fill: $app_darkmode_text1;
                }
              }
            }
          }
        }

        .buttons {
          position: absolute;
          right: 6px;
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          display: flex;
          align-items: center;
          box-sizing: border-box;

          .confirm {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            margin: 0;

            button {
              padding-left: 8px;
              padding-right: 8px;
            }
          }

          .spinner_wrap {
            margin-top: 5px;
          }

          .show_props {
            position: relative;
            margin-right: 6px;
            height: 25px;
            width: 35px;
            box-sizing: border-box;

            button {
              height: 25px;
              width: 35px;
              border: none;
            }

            &_ic {
              position: relative;
              height: 25px;
              width: 35px;
              left: 0;
              right: 0;
              display: inline-block;
              box-sizing: border-box;

              & > ul {
                position: relative;
                height: 25px;
                width: 35px;
                left: 0;
                top: 0;
                text-align: center;
                list-style: none;
                list-style-type: none;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;

                & > li {
                  position: relative;
                  display: inline-block;
                  height: 4px;
                  width: 4px;
                  border-radius: 50%;
                  background: $app_black_shade2;
                  margin: 0;
                  left: 0;
                  // margin-left: 2px;
                  margin-bottom: 3px;
                  text-align: center;
                }

                & > li:nth-child(2),
                & > li:nth-child(3) {
                  margin-left: 4px;
                }
              }

              &_dark {
                & > ul {
                  & > li {
                    background: $app_darkmode_text1;
                  }
                }
              }
            }

            &_dark {
              button {
                padding: 8px 4px;
                background: rgba($color: $app_darkmode_text1, $alpha: 0.02);
              }
            }
          }
        }
      }

      .expand {
        display: none;
        height: 0;
        width: 0;
        overflow: hidden;
      }

      .spaces {
        position: relative;

        &_desktop {
          display: none;
        }
      }

      .first {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0;
        // border: 1px solid red;

        &_w_prop,
        &_no_prop {
          width: 100%;
          margin: 0;
        }

        &_maximize {
          width: 100%;
          height: 100%;
        }

        .error_input {
          position: fixed;
          top: 105px;
          left: 20px;
          right: unset;
          bottom: unset;
        }

        .task_title {
          position: absolute;
          width: 100%;
          margin: 0;
          bottom: calc(100% - 105px); // height of task_desc + 5px
          padding: 0 5px;
          // border: 1px solid red !important;
          // box-sizing: border-box;

          .to_edit {
            top: 9px;
          }

          &_input {
            min-width: 100%;
            width: 100%;
            width: 100%;
          }

          &_placeholder {
            width: 70%;
            margin-left: 15%;
            margin-top: 50px;
          }
        }

        .info {
          display: none;
        }

        .task_description_wrap {
          position: absolute;
          margin: 0;
          padding-right: 0;
          box-sizing: border-box;
          top: 108px;
          height: calc(100% - 108px);
          width: 100%;
          // border: 10px solid red;
        }

        .actions {
          display: none;
          height: 0;
          overflow: hidden;

          &_on_expand {
            top: unset;
          }
        }
      }

      .second {
        position: absolute;
        height: 100%;
        width: 100%;
        margin: 0;
        border-radius: 0;
        padding-top: 5px;
        padding-bottom: 8px;
        overflow: hidden;
        // overflow-x: hidden;
        // border: 2px solid red;
        box-sizing: border-box;
        overflow-y: scroll;

        .title {
          font-size: 100%;

          & > p {
            font-size: 100%;
          }
        }

        &_show {
          display: inline-block;
          z-index: calc(#{$high_front} + 1);
          height: 100%;
          width: 100%;
          top: 0;
          padding-bottom: 80px;
          overflow-y: scroll;
        }

        &_hide {
          opacity: 0;
          height: 0;
          width: 0;
          top: -100%;
          overflow: hidden;
        }

        .property {
          &_priority {
            select {
              font-size: 12px;
            }
          }
        }

        &_mobile_actions {
          position: relative;
          width: 100%;
          height: 40px;
          padding-right: 22px;
          margin-left: -22px;
          left: 0;
          display: inline-block;

          .hide {
            position: absolute;
            top: 5px;
            left: 5px;
            display: inline-block;
            height: 30px;
            width: 30px;

            button {
              position: relative;
              background: transparent;
              height: 30px;
              width: 30px;

              & > div {
                position: absolute;
                // height: 25px;
                // width: 25px;
                display: inline-block;
                text-align: center;
                // background: url($arrow_down_black_2);
                // background-position: center;
                // background-size: contain;
                // background-repeat: no-repeat;
                // transform: rotate(90deg);
              }
            }

            &_dark {
              button {
                svg {
                  fill: $app_darkmode_text1;
                  path {
                    fill: $app_darkmode_text1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
