@import '../../theme/color.scss';
@import '../../theme/commons.scss';
@import '../../theme/brand.scss';

.login_v2 {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;

  .actual {
    position: relative;
    display: inline-block;
    width: 440px;
    margin: 0;
    margin-left: calc((100% - 440px) / 2);
    padding: 20px 15px;
    padding-bottom: 25px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid $app_gray_shade3;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);

    .logo {
      width: 100%;
      display: inline-block;
      text-align: center;

      &_wrap {
        position: relative;
        display: inline-block;
        text-align: center;

        & > div:nth-child(1) {
          // border: 2px solid red;
          box-sizing: border-box;

          & > div:nth-child(1) {
            height: 45px;
            width: 45px;

            & > div {
              height: 45px;
              width: 45px;
            }
          }
        }
      }
    }

    .username {
      margin-top: 25px;
    }

    .username,
    .password {
      position: relative;
      display: inline-block;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;

      .input {
        position: relative;
        width: 100%;
        border-radius: 5px;

        & > div:nth-child(1) {
          margin-bottom: 15px;

          div {
            & > p:nth-child(2) {
              width: calc(100% - 20px) !important;
            }
          }

          label {
            font-weight: 600 !important;
            font-family: 'Manrope' !important;
            font-size: 90% !important;
          }
        }

        input[type='text'],
        input[type='password'] {
          font-size: 80% !important;
          font-weight: 500 !important;
          font-family: 'Manrope' !important;
          height: 40px;
          letter-spacing: 0.6px !important;
        }
      }
    }

    .password {
      .input {
        position: relative;
        // border: 1px solid red;
        box-sizing: border-box;

        input[type='text'],
        input[type='password'] {
          padding-right: 35px;
          box-sizing: border-box;
        }
      }

      &_show {
        position: absolute;
        top: 38px;
        right: 10px;
        display: inline-block;
        // border: 1px solid red;
        box-sizing: border-box;

        svg {
          fill: $app_gray_shade4;

          path {
            fill: $app_gray_shade4;
          }
        }
      }
    }

    .username,
    .password {
      &_dark {
        .input {
          label,
          span {
            color: $app_darkmode_text1 !important;
          }

          input[type='text'],
          input[type='password'] {
            background: transparent;
            border: 1px solid $app_darkmode_border1;
            background: $app_darkmode_background3;
            color: $app_darkmode_text1;
          }
        }

        .password_show {
          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    .actions {
      position: relative;
      display: inline-block;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;

      .remember_login {
        position: relative;
        display: inline-block;
        width: 100%;

        svg {
          path {
            fill: white;
          }
        }

        &_dark {
          label {
            & > div {
              background: transparent !important;

              svg {
                fill: transparent !important;
                path {
                  fill: transparent !important;
                }
              }
            }
          }

          p {
            color: rgba($color: $app_darkmode_text1, $alpha: 0.7);
            & > a,
            & > span {
              color: $app_darkmode_text1;
            }
          }

          span {
            color: $app_darkmode_text1 !important;
          }
        }

        &_active_dark {
          label {
            & > div {
              background: $app_blue_shade3 !important;

              svg {
                fill: $app_white_shade3 !important;

                path {
                  fill: $app_white_shade3 !important;
                }
              }
            }
          }

          span {
            color: $app_darkmode_text1 !important;
          }
        }
      }

      .status {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 5px;

        & > div:nth-child(1) {
          display: inline;
          text-align: center;

          & > h5 {
            font-weight: 500;
          }
        }
      }

      .forgot_pw {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 17px;

        & > p {
          position: relative;
          text-align: center;
          display: inline-block;
          font-size: 85%;

          a {
            font-weight: 600;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .redirect_signup {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 14px;

        & > h5 {
          line-height: 20px;
          display: inline-block;
          text-align: center;
          font-size: 100%;

          p {
            font-weight: 500;
            font-size: 83%;
            position: relative;
            display: inline-block;
            text-align: center;

            a {
              text-decoration: none;
              font-weight: 700;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .redirect {
        position: relative;
        width: 100%;
        margin-top: 15px;
        display: inline-block;
        text-align: center;

        & > h5 {
          font-weight: 700;
          line-height: 20px;
          text-align: center;

          p {
            color: $app_gray_shade2;

            a {
              color: $app_gray_shade2;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &_dark {
          & > h5 {
            color: $app_darkmode_text1;

            p {
              color: $app_darkmode_text1;

              a {
                color: $app_darkmode_text1;
              }
            }
          }
        }
      }

      .confirm {
        position: relative;
        width: 100%;
        height: 40px;

        &_w_status {
          margin-top: 12px;
        }

        &_wno_status {
          margin-top: 25px;
        }
      }
    }

    .ic_w_title {
      position: relative;
      width: 100%;
      display: inline-block;
      margin-top: 15px;

      .ic_only {
        position: relative;
        height: 125px;
        width: 80px;
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        background: url($bear_black_border_one);
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;

        &_dark {
          filter: invert(70%);
        }
      }

      .title {
        width: calc(100% - 110px);
        margin-left: 15px;
        display: inline-block;
        vertical-align: middle;

        & > h2 {
          display: inline-block;
          font-weight: 800;
          font-size: 175%;
          text-align: left;
        }

        & > h5 {
          position: relative;
          text-align: left;
          margin-left: 5px;
          margin-top: 5px;
          font-weight: 600;
          color: $app_gray_shade2;
          line-height: 20px;
        }

        &_dark {
          & > h2,
          & > h5 {
            color: $app_darkmode_text1;
          }

          & > h5 {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &_dark {
    .actual {
      background: $app_darkmode_background_header1;
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
    }

    background: $app_darkmode_background1;
  }
}
@media screen and (max-width: 900px) {
  .login_v2 {
    .actual {
      border: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &_dark {
      .actual {
        background: transparent;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .login_v2 {
    .actual {
      width: 95%;
      margin: 0;
      margin-left: 2.5%;
    }
  }
}
