@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/assets.scss';

.task_header {
  position: fixed;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  display: inline-block;
  z-index: $high_front;
  top: 0;
  padding: 0 8px;
  padding-top: 8px;
  padding-bottom: 10px;
  min-height: 60px;
  border-bottom: 1.2px solid rgba($color: $app_white_shade3, $alpha: 0.6);
  box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  -moz-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  -webkit-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  background: $app_white_absolute;

  &_ios {
    padding-top: 45px;
    top: -1px;
  }

  &_dark {
    background: $app_darkmode_background_header1;
    -moz-box-shadow: 0px 4px 50px $app_black_shade5;
    -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
    box-shadow: 0px 4px 50px $app_black_shade5;
    border: none;
  }

  & > div:nth-child(1) {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 5px 0;

    .logo_wrap {
      position: relative;
      min-height: 100%;
      display: inline-block;
      vertical-align: middle;
      top: 0;
      text-align: center;
      margin-left: 10px;
      vertical-align: middle;
      // border: 1px solid red;
      box-sizing: border-box;

      .chamu_logo {
        & > div:nth-child(1) {
          & > div:nth-child(1) {
            height: 40px;
            width: 40px;
          }
        }
      }

      .space_logo {
        position: relative;
        // border: 1px solid red;
        box-sizing: border-box;

        & > img {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          height: 35px;
          width: 34px;
          border-radius: 5px;
          overflow: hidden;
          object-fit: cover;
          object-position: center;
          margin-right: 12px;
          box-sizing: border-box;
          border: 2px solid rgba($color: #e6e8f0, $alpha: 0.1);
        }

        .space_name {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          // border: 2px solid red;

          & > h5 {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            line-height: 35px;
            text-align: center;
            font-weight: 700;
            font-size: 100%;
            letter-spacing: 0.35px;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $app_black_shade1;
          }
        }

        &_dark {
          .space_name {
            & > h5 {
              color: $app_white_light;
            }
          }
        }
      }

      .cover {
        & > div:nth-child(1) {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
        }

        button {
          position: relative;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;

          a {
            text-decoration: none;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
      }
    }

    .redirect {
      position: absolute;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      top: 6px;
      margin: 0;
      // top: 0;
      // border: 1px solid red;

      &_w_user {
        // margin-left: calc(100% - 145px);
        right: 16px;
      }

      &_no_user {
        // margin-left: calc(100% - 200px);
        right: 16px;
      }

      .copy_link {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;

        &_tip {
          display: none;
          min-width: 120px;
        }

        &:hover {
          .copy_link_tip {
            display: block;
          }
        }

        &_button {
          position: relative;
          padding-left: 8px;
          padding-right: 8px;
          overflow: hidden;

          .ic,
          & > h5 {
            position: relative;
            display: inline-block;
            vertical-align: top;
            // display: none;
          }

          & > h5 {
            font-size: 13px;
            font-weight: 700;
            color: $app_black_shade4;
          }

          .ic {
            margin-right: 8px;
            padding-top: 6px;
            // box-sizing: border-box;
            // border: 1px solid red;

            svg {
              fill: $app_gray_shade6;
              height: 12px;
              width: 12px;
            }
          }
        }

        &_dark {
          h5 {
            color: $app_darkmode_text1;
          }

          button {
            &:hover {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }

      .read_only {
        position: relative;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
        margin-right: 16px;

        strong {
          color: #996a13;
          padding: 0 6px;
          height: 20px;
          text-align: center;
          line-height: 20px;
        }
      }

      .login_signup {
        position: relative;
        display: inline-block;
        text-align: center;
        // border: 1px solid red;
        vertical-align: middle;
        box-sizing: border-box;

        .login,
        .signup {
          position: relative;
          display: inline-block;
          vertical-align: middle;

          &_button {
            h5 {
              font-weight: 700;
              font-size: 105%;
            }
          }

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }

        .signup {
          background: $app_dominant_purple;
          border-radius: 4px;
          overflow: hidden;

          &:hover {
            opacity: 0.9;
          }

          &_button {
            background: $app_dominant_purple;

            h5 {
              color: $app_white_absolute;
            }
          }
        }

        .login {
          &_dark {
            button {
              &:hover {
                background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
              }
            }
          }
        }
      }

      &_dashboard {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        text-align: center;
        vertical-align: middle;

        &_button {
          position: relative;
          display: inline-block;
          text-align: center;

          button {
            display: inline-block;
            text-align: center;
            height: 30px;
            width: 30px;
          }
        }

        &_hover_msg {
          min-width: 100px;
          display: none;
          overflow: hidden;
        }

        &:hover {
          .redirect_dashboard_hover_msg {
            display: block;
          }
        }

        .cover {
          a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
      }

      &_avatar {
        position: relative;
        height: 40px;
        width: 40px;
        top: 0;
        left: 0;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;

        &_hover_msg {
          min-width: 100px;
          display: none;
          overflow: hidden;

          h5 {
            min-width: 110px;
          }
        }

        &:hover {
          .redirect_avatar_hover_msg {
            display: block;
          }
        }

        .cover {
          cursor: pointer;

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
          }
        }

        & > div:nth-child(1),
        & > h5 {
          position: relative;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 50%;
          overflow: hidden;
        }

        & > h5 {
          position: relative;
          font-weight: 700;
          font-size: 14.5px;
          color: $app_white_absolute;
          text-align: center;
          background: $app_black_shade1;
          line-height: 42px;
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .task_header {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $app_white_absolute;
    z-index: $high_front;

    &_ios {
      padding-top: 42px;
    }

    &_dark {
      background: $app_darkmode_background_header1;
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
      border: none;
    }

    .logo_wrap {
      margin: 0;

      .space_logo {
        .space_name {
          margin-top: 4px;
        }
      }
    }

    & > div:nth-child(1) {
      .redirect {
        right: 0;

        .copy_link {
          background: transparent;

          &_button {
            &:active,
            &:hover {
              background: transparent;
            }
          }

          &_tip {
            display: none;
            overflow: hidden;
            height: 0;
          }
        }

        .read_only {
          margin: 0 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .task_header {
    & > div:nth-child(1) {
      .logo_wrap {
        .space_logo {
          .space_name {
            box-sizing: border-box;
            max-width: 150px;

            & > h5 {
              max-width: 150px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .task_header {
    & > div:nth-child(1) {
      .logo_wrap {
        .space_logo {
          .space_name {
            box-sizing: border-box;
            max-width: 120px;

            & > h5 {
              max-width: 120px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .task_header {
    & > div:nth-child(1) {
      .redirect {
        position: absolute;
        right: -4px;

        .copy_link {
          display: none;
          overflow: hidden;
        }
      }
    }
  }
}
