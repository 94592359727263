@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.clear_task {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;

  .actual {
    position: relative;
    padding: 15px 15px;
    min-height: 50px;
    width: 350px;
    border-radius: 8px;
    background: $app_white_absolute;
    border: 1px solid $app_white_shade3;
    box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
    -webkit-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);
    -moz-box-shadow: 0px 0px 5px -2px rgba(232, 232, 232, 0.7);

    & > h4:nth-child(1) {
      font-size: 90%;
      font-weight: 700;
      max-width: calc(100% - 5px);
      margin-left: 5px;
    }

    .spinner_wrap {
      position: relative;
      width: 100%;
      margin-top: 14px;

      .raw {
        position: relative;
        display: inline-block;
        margin-left: calc((100% - 40px) / 2);

        &_spin {
          height: 40px;
          width: 40px;
        }
      }
    }

    .actions {
      position: relative;
      margin-top: 40px;

      .confirm {
        height: 35px;
        width: 120px;
        &:hover {
          div {
            box-shadow: 0 0 3px 1px #ada2dd;
          }
        }
      }

      .cancel {
        position: relative;
        height: 36px;
        width: 120px;
        overflow: hidden;
        margin-left: 10px;
        border-radius: 6px;

        &_raw {
          position: relative;
          height: 35px;
          width: 120px;

          & > p {
            position: relative;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .delete_task {
    .actual {
      width: calc(90% - 30px);
    }
  }
}
