@import '../../../../theme/color.scss';
@import '../../../../theme/commons.scss';
@import '../../../../theme/scroll.scss';

.mention {
  position: absolute;
  padding: 6px 8px;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 4);
  border: 1px solid $app_white_shade3;
  background: #ffffff;
  border-radius: 4px;
  display: none;

  &_hide {
    display: none;
  }

  &_loading {
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    box-sizing: border-box;
    width: 245px;
    text-align: center;

    & > div {
      position: relative;
      padding: 0;
    }
  }

  &_show {
    display: inline-block;
  }

  & > ul {
    position: relative;
    list-style-type: none;
    list-style: none;
    width: 245px;
    min-height: 40px;
    max-height: 185px;
    overflow: hidden;
    overflow-y: scroll;
    @include custom_scroll;

    & > li {
      position: relative;
      padding: 2px 8px;
      cursor: pointer;

      & > p,
      .avatar {
        position: relative;
        display: inline-block;
      }
      .avatar {
        vertical-align: middle;
      }

      & > p {
        font-weight: 600;
        font-size: 85%;
        letter-spacing: 0.4px;
        margin-left: 8px;
        max-width: 230px;
        box-sizing: border-box;
      }

      &:hover {
        & > p {
          color: $app_dominant_purple;
        }
      }
    }

    & > li:not(:nth-child(1)) {
      margin-top: 3px;
    }
  }
}

@media screen and (max-width: 900px) {
  .mention {
    & > ul {
      max-height: 158px;
    }
  }
}
