@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';
@import '../../../theme/assets.scss';

.tasks_with_access {
  position: absolute;
  right: 10px;
  display: inline-block;
  vertical-align: middle;
  // border: 1px solid red;

  .list {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    & > ul {
      position: relative;
      right: 0;
      height: 35px;
      display: flex;
      justify-content: flex-end;
      list-style: none;
      list-style-type: none;

      & > li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 3px;

        .avatar {
          height: 32px;
          width: 32px;

          & > div:nth-child(1) {
            h5 {
              line-height: 32px;
            }
          }
        }
      }

      & > li:not(:nth-child(1)) {
        margin-left: -3px;
      }
    }
  }

  .show_more {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-top: 2px;

    & > button {
      background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
      padding: 0px 10px;

      & > h5 {
        font-weight: 700;
        font-size: 14px;
      }
    }

    &_dark {
      & > button {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.02);

        &:hover {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
        }

        & > h5 {
          color: $app_white_light;
        }
      }
    }
  }
}

@media screen and (max-width: 949px) {
  .tasks_with_access {
    right: 45px;

    .list {
      & > ul {
        margin-top: 5px;
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tasks_with_access {
    .list {
      & > ul {
        height: 32px;

        & > li {
          margin-top: 0;
          margin-bottom: 0;

          .avatar {
            height: 30px;
            width: 30px;

            & > div:nth-child(1) {
              h5 {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
