@import '../../theme/commons.scss';
@import '../../theme/color.scss';

.common_button_no_disabled {
  &:hover {
    box-shadow: 1px 1px 18px 0px rgba(97, 60, 231, 0.5);
    -webkit-box-shadow: 1px 1px 18px 0px rgba(97, 60, 231, 0.5);
    -moz-box-shadow: 1px 1px 18px 0px rgba(97, 60, 231, 0.5);
  }
}

.common_button {
  position: relative;
  background: $app_dominant_purple;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 1px 1px 18px -5px rgba(97, 60, 231, 0.75);
  -webkit-box-shadow: 1px 1px 18px -5px rgba(97, 60, 231, 0.75);
  -moz-box-shadow: 1px 1px 18px -5px rgba(97, 60, 231, 0.75);

  &_disabled {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba($color: #fff, $alpha: 0.5);
  }

  & > button {
    position: relative;
    height: 100%;
    width: calc(100% - 20px);
    margin: 0 10px;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80%;

    & > p {
      position: relative;
      color: $app_white_absolute;
      font-weight: 700;
      text-align: center;
      display: inline-block;
    }
  }
}
