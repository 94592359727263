/** Task priority colors */
@import './assets.scss';

.colors {
  position: relative;
  height: 23px;
  width: 23px;
  margin-left: 6px;
  border-radius: 5px;
  overflow-y: hidden;
  box-sizing: border-box;

  & > span {
    top: 0;
    left: 0;
    background: url($arrow_down_black);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40% 40%;
    transform: rotate(-90deg);
  }

  &_normal {
    background-color: #d8f0d1 !important;
  }

  &_high {
    background-color: #fcaeaf !important;
  }

  &_low {
    background-color: rgba($color: #e5ebff, $alpha: 1) !important;
  }
}
