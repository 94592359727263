@import '../../../../theme/color.scss';
@import '../../../../theme/commons.scss';
@import '../../../../theme/scroll.scss';

.marks_heading2 {
  position: absolute;
  display: inline-block;
  top: 55px;
  right: 3px;
  padding: 0 6px;
  z-index: calc(#{$high_front} + 2);
  background: $app_white_absolute;
  border-radius: 4px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border: 1px solid $app_white_shade3;
  transition: padding 0.1s ease-out;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 300px;
  padding: 4px 8px;

  @include custom_scroll;

  &_icon {
    position: relative;
    height: 28px;
    width: 25px;
    left: 0;
    right: 0;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;

    & > ul {
      position: relative;
      height: 28px;
      width: 25px;
      left: 0;
      top: 0;
      text-align: center;
      list-style: none;
      list-style-type: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 13px;
      transform: scaleX(-1);
      margin: 0;
      vertical-align: top;
      cursor: pointer;

      & > li {
        position: relative;
        height: 3.5px;
        width: 12px;
        border-radius: 8px;
        background: $app_black_shade2;
        margin: 0;
        left: 0;
        // margin-left: 2px;
        margin-bottom: 3px;
        text-align: center;
        margin-left: 2px;
        margin-top: 2px;
      }

      & > li:nth-child(2) {
        width: 8px;
      }

      & > li:nth-child(2),
      & > li:nth-child(3) {
        margin-top: 0;
      }

      & > li:nth-child(3) {
        width: 17px;
      }
    }
  }

  .list {
    position: relative;
    display: none;
    // border: 1px solid red;

    &_active {
      &::before {
        position: absolute;
        left: -5px;
        height: 90%;
        top: 5%;
        bottom: 0;
        width: 4px;
        border-radius: 7px;
        background: $app_dominant_purple;
        content: ' ';
      }
    }

    &_inactive {
      &::before {
        position: absolute;
        left: -5px;
        height: 90%;
        top: 5%;
        bottom: 0;
        width: 4px;
        border-radius: 7px;
        background: $app_white_shade7;
        content: ' ';
      }
    }

    & > ul {
      position: relative;
      list-style: none;
      list-style-type: none;
      display: inline-block;
      min-width: 160px;
      max-width: 200px;
      box-sizing: border-box;

      & > li {
        position: relative;
        box-sizing: border-box;
        padding-left: 5px;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;

        & > h5 {
          font-weight: 600;
          width: 100%;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;
          -webkit-user-select: none;
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 12px;
      }
    }
  }

  &:hover,
  &:active {
    .marks_heading2_icon,
    & > div:nth-child(1) {
      opacity: 0;
      z-index: 1;
      height: 0;
      width: 0;
    }

    .list {
      box-sizing: border-box;
      display: inline-block;
      padding: 8px 0;
      padding-bottom: 4px;
    }
  }
}

@media screen and (max-width: 900px) {
  .marks_heading2 {
    display: none;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}
