@import '../../theme/color.scss';
@import '../../theme/commons.scss';

.custom_toaster {
  position: fixed;
  display: inline-block;
  padding: 8px 12px;
  // padding-right: 24px;
  border-radius: 6px;
  padding-right: 8px;
  background: $app_white_absolute;
  border: 1px solid $app_white_shade3;
  box-shadow: 2px -1px 25px 13px rgba(230, 232, 240, 0.4);
  -webkit-box-shadow: 2px -1px 25px 13px rgba(230, 232, 240, 0.4);
  -moz-box-shadow: 2px -1px 25px 13px rgba(230, 232, 240, 0.4);
  z-index: calc(#{$high_front} + 3);

  .ic,
  & > h5,
  & > p {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    // border: 1px solid red;
    box-sizing: border-box;
  }

  & > h5,
  & > p {
    position: relative;
    font-weight: 700;
    font-size: 76%;
    margin-left: 8px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    span {
      font-size: 102%;
      font-weight: 800;
    }
  }

  .ic {
    height: 16px;
    width: 16px;
    display: inline-block;
    text-align: center;

    .warning,
    .success,
    .danger,
    .info {
      position: relative;
      display: inline-block;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .close {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;

    svg {
      height: 12px;
      width: 12px;
      margin-top: 1px;
    }
  }

  &_default {
    top: 30px;
  }

  &_bottom {
    top: unset;
    bottom: 30px;
  }

  &_dark {
    background: $app_darkmode_background_header1;
    -moz-box-shadow: 0px 4px 50px $app_black_shade5;
    -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
    box-shadow: 0px 4px 50px $app_black_shade5;
    border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.05);

    & > h5,
    & > h4,
    & > p {
      color: $app_darkmode_text1;

      span {
        color: $app_darkmode_text1;
      }
    }

    .close {
      svg {
        fill: $app_darkmode_text1;
        path {
          fill: $app_darkmode_text1;
        }
      }

      button {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.02);

        &:hover {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.1);
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .custom_toaster {
    border: 1.5px solid $app_white_shade3;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;

    &_default {
      top: 12px;
    }

    &_dark {
      background: $app_darkmode_background_header1;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      border: 1.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.05);
    }
  }
}

@media screen and (max-width: 600px) {
  .custom_toaster {
    & > h5 {
      max-width: 230px;
    }
  }
}
