@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/task.scss';
@import '../../theme/assets.scss';
@import './TaskAnnex.scss';
@import '../../theme/priority.scss';

.task_view_wrap {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: $app_white_absolute;
  box-sizing: border-box;

  &_dark {
    background: $app_darkmode_background1;
  }

  &_raw {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding-top: 120px;
    padding-bottom: 40px;

    .content {
      position: relative;
      width: 94%;
      margin-left: 3%;
      padding: 10px 0;
      // border: 1px solid red;

      .second_has_actions {
        width: 220px;
      }

      .first,
      .second {
        box-sizing: border-box;
      }

      .first {
        position: relative;
        width: 660px;
        padding: 0 5px;
        margin-right: 5px;

        .status {
          position: relative;
          width: calc(100% - 10px);
          padding-left: 15px;
          box-sizing: border-box;

          &_views {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            margin-left: 12px;

            & > h5,
            & > h4 {
              font-weight: 700;
            }
          }

          &_actual {
            display: inline-block;
            text-align: center;
            background: $app_gray_shade1;
            border-radius: 4px;
            padding: 1px 13px;
            vertical-align: middle;

            & > h5 {
              display: inline-block;
              font-weight: 700;
              font-size: 80%;
              line-height: 22px;
              color: $app_gray_shade2;
            }

            &_dark {
              background: transparent;
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);

              & > h5 {
                color: $app_darkmode_text1;
              }
            }
          }

          &_priority {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;

            & > div:nth-child(1) {
              margin-bottom: -2px;
            }
          }

          &_relation_access {
            position: relative;
            display: inline-block;
            margin-left: 5px;
            background: $app_gray_shade1;
            border-radius: 4px;
            padding: 1px 13px;
            vertical-align: middle;

            & > h5 {
              display: inline-block;
              font-weight: 700;
              font-size: 80%;
              line-height: 22px;
              text-align: center;
              color: $app_gray_shade2;
            }

            &_dark {
              background: transparent;
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);

              & > h5 {
                color: $app_darkmode_text1;
              }
            }
          }
        }

        .title {
          position: relative;
          width: 100%;
          padding: 10px 0;
          margin-top: 10px;

          &_placeholder {
            position: relative;
            width: 70%;
            height: 15px;
            border-radius: 20px;
            background: $app_gray_shade1;
            animation: opacity-load 2s ease-in-out infinite;

            &_dark {
              background: $app_darkmode_background_header1;
            }
          }

          &_actual {
            max-width: 85%;
            overflow-wrap: break-word;
            padding: 5px 0;
            border-left: 5px solid $app_yellow_shade1;
            padding-left: 15px;

            & > h2 {
              font-size: 200%;
              font-weight: 700;
              padding: 2px 0;
              text-align: left;
              display: inline-block;
            }

            .last_updated {
              position: relative;
              display: block;
              margin-top: 5px;
              margin-left: 2px;

              & > p {
                display: inline-block;
                font-weight: 500;
                font-size: 13px;
                color: $app_gray_shade2;

                span {
                  color: $app_gray_shade2;
                }
              }

              &_dark {
                & > p {
                  color: $app_darkmode_text1;
                  opacity: 0.5;

                  span {
                    color: $app_darkmode_text1;
                  }
                }
              }
            }

            .exposure_info {
              display: none;
            }
          }
        }

        .author {
          position: relative;
          width: 100%;
          margin: 7px 0;
          padding-left: 17px;
          box-sizing: border-box;
          // border: 2px solid red;

          &_avatar {
            position: relative;
            height: 40px;
            width: 40px;
            border-radius: 50% 50%;
            box-sizing: border-box;
            margin-right: 10px;
            overflow: hidden;
            background: rgba($color: $app_dominant_purple_bg, $alpha: 0.7);

            & > h3 {
              color: $app_dominant_purple;
              font-weight: 600;
            }

            &_img {
              position: absolute;
              height: 100%;
              width: 100%;
              object-fit: cover;
              display: block;
              top: 0;
              left: 0;

              &_hide {
                visibility: hidden;
                width: 0;
                height: 0;
              }

              &_show {
                visibility: visible;
              }
            }
          }

          &_actual {
            max-width: 80%;
            overflow-wrap: break-word;
            & > p {
              overflow-wrap: break-word;
              font-size: 90%;
              font-weight: 700;
            }
          }

          &_placeholder {
            position: relative;
            width: 40%;
            height: 15px;
            margin-left: -17px;
            border-radius: 20px;
            background: $app_gray_shade1;
            animation: opacity-load 2s ease-in-out infinite;

            &_dark {
              background: $app_darkmode_background_header1;
            }
          }

          &_dark {
            .author_avatar {
              background: $app_black_shade1;

              & > h3,
              & > h4 {
                color: $app_white_shade1;
              }
            }
          }
        }

        .task_tags {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          margin-top: 5px;
          // border: 1px solid red;
          display: inline-block;
          padding-left: 12px;

          .list {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: middle;

            & > ul {
              width: 100%;
              position: relative;
              list-style: none;
              list-style-type: none;
              float: left;
              vertical-align: top;
              margin-bottom: 10px;

              & li {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                border-radius: 6px;
                margin-top: 4px;
                margin-right: 6px;
                box-sizing: border-box;

                h5 {
                  font-size: 78%;
                  font-weight: 600;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }

        .description_wrap {
          position: relative;
          width: calc(100% - 16px);
          padding-left: 16px;
          margin-top: 25px;
          box-sizing: border-box;

          &_placeholder {
            position: relative;
            width: 100%;
            margin-top: 50px;

            & > ul {
              position: relative;
              display: inline-block;
              width: calc(100% - 14px);
              padding: 0 7px;
              list-style: none;
              list-style-type: none;

              & > li {
                position: relative;
                height: 15px;
                background: $app_gray_shade1;
                border-radius: 20px;
                animation: opacity-load 2s ease-in-out infinite;
              }

              & > li:nth-child(1) {
                width: 20%;
              }

              & > li:nth-child(2) {
                width: 15%;
              }

              & > li:not(:nth-child(1)) {
                margin-top: 20px;
              }
            }

            &_dark {
              & > ul {
                & > li {
                  background: $app_darkmode_background_header1;
                }
              }
            }
          }

          .description_input {
            width: 100%;

            & > div:nth-child(1) {
              width: 100%;
              padding: 6px 0 !important;
              box-sizing: border-box;
              font-size: 15px !important;
              overflow-y: visible !important;

              @include task_description;

              img {
                cursor: zoom-in;
              }
            }

            &_dark {
              & > div:nth-child(1) {
                overflow-y: visible !important;
                @include task_description_dark;
              }
            }
          }
        }
      }

      .second {
        position: relative;
        display: inline-block;
      }
    }

    .something_wrong,
    .invalid_view {
      position: relative;
      height: 320px;
      margin-top: 10px;
      width: 100%;

      .msg {
        max-width: 80%;

        .ic {
          position: relative;
          width: 80px;
          height: 65px;
          background: url($llama_icon);
          background-position: center;
          background-size: 60% 60%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }

        & > h4 {
          font-weight: 700;
          font-size: 105%;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: calc(#{$max_width_fix} - 1px)) {
  .task_view_wrap {
    &_raw {
      width: 95%;
      margin-left: 2.5%;

      .content {
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (min-width: $max_width_fix) {
  .task_view_wrap {
    &_raw {
      width: calc(#{$max_width_fix} - 95px);
      margin-left: calc((100% - (#{$max_width_fix} - 95px)) / 2);
      // border: 2px solid red;
      box-sizing: border-box;

      .content {
        box-sizing: border-box;

        .second_has_actions {
          width: 300px;
        }

        .first {
          width: 800px;
        }
      }
    }
  }
}

@media screen and (max-width: $max_width_fix) {
  .task_view_wrap {
    &_raw {
      // border: 2px solid blue;
      box-sizing: border-box;

      .content {
        box-sizing: border-box;

        .second_has_actions {
          // border: 2px solid violet;
          width: 300px;
        }

        .first {
          width: 800px;
          // border: 2px solid violet;
          box-sizing: border-box;
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .task_view_wrap {
    &_raw {
      .content {
        .first {
          .description_wrap {
            .description_input {
              & > div:nth-child(1) {
                font-size: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 949px) {
  .task_view_wrap {
    &_raw {
      position: relative;
      width: 100%;
      margin: 0;
      padding-top: 130px;

      .content {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;

        .first {
          box-sizing: border-box;
          width: 100%;
          padding: 0;

          .title {
            &_actual {
              max-width: unset;
              width: 100%;

              & > h2 {
                overflow-wrap: break-word;
              }

              .exposure_info {
                margin-top: 5px;
                display: block;
                padding-left: 2px;
              }
            }
          }

          .author {
            &_actual {
              overflow-wrap: break-word;
              max-width: 100%;
            }
          }

          .description_wrap {
            .description_input {
              & > div:nth-child(1) {
                font-size: 100% !important;

                & > p {
                  & > img {
                    width: 100%;
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        .second {
          margin: 35px 15px;
          margin-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .task_view_wrap {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    min-height: unset;
    overflow: hidden;
    overflow-y: scroll;
    // border: 2px solid red;

    &_raw {
      padding-top: 130px;
      padding-bottom: 0;
    }
  }
}
