.app {
  &_placeholder {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 900px) {
  body,
  html {
    font-size: 13px;
  }

  #root {
    font-size: 13px;
  }
}
