@import '../../theme/commons.scss';
@import '../../theme/color.scss';
@import '../../theme/scroll.scss';

.create_task_modal_wrap {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &_ios {
    height: 100vh;
  }

  .raw {
    position: relative;
    background: $app_white_absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    border: 1.5px solid $app_white_shade3;
    border-radius: 7px;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;

    .close {
      position: absolute;
      right: 3px;
      top: 6px;
      display: inline-block;

      &_tip {
        position: absolute;
        top: 102%;
        right: 0;
        display: none;
      }

      &:hover {
        .close_tip {
          display: block;
        }
      }

      &_button {
        position: relative;
        width: 15px;
        height: 25px;
        background: transparent;

        &:hover {
          background: transparent;
          background-color: transparent;
        }
      }

      &_dark {
        button {
          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    &_expand {
      position: relative;
      top: 0;
      // bottom: 0;
      height: 99vh !important;
      width: 70vw;
      z-index: $high_front;
      border-radius: 0;
      background: $app_white_absolute;

      &::before {
        position: fixed;
        content: ' ';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: $app_gray_shade5;
      }

      &_dark {
        background: $app_darkmode_background1;

        &::before {
          background: $app_darkmode_background1;
        }
      }
    }

    &_w_prop {
      width: 1100px;
    }

    &_no_prop {
      width: 1100px;
    }

    &_dark {
      border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
      background: $app_darkmode_background1;
      // background: rgba($color: $app_darkmode_background2, $alpha: 1);
      // border: 2px solid red;
      box-sizing: border-box;
    }
  }
}

@media screen and (min-width: 900px) and (min-height: 605px) {
  // if on desktop screen
  .create_task_modal_wrap {
    overflow: hidden;
    .raw {
      &_default {
        height: 70vh;
      }
    }
  }
}

@media screen and (min-width: 900px) and (min-height: 620px) {
  // if on desktop screen
  .create_task_modal_wrap {
    overflow: hidden;

    .raw {
      &_default {
        height: 85vh;
      }
    }
  }
}

@media screen and (max-height: 605px) and (min-width: 900px) {
  // desktop width but user resizes browser maybe
  .create_task_modal_wrap {
    overflow-y: scroll;
    box-sizing: border-box;
    align-items: unset;

    .raw {
      margin: 20px 0;

      &_default {
        height: 550px;
      }
    }
  }
}

@media screen and (min-height: 760px) and (min-width: 901px) {
  .create_task_modal_wrap {
    .raw {
      &_default {
        height: 590px;
      }
    }
  }
}

@media screen and (min-width: 1480px) {
  .create_task_modal_wrap {
    .raw {
      &_w_prop {
        width: 1330px;
      }

      &_no_prop {
        width: 1330px;
      }
    }
  }
}

@media screen and (min-width: 1350px) and (max-width: 1480px) {
  .create_task_modal_wrap {
    .raw {
      &_w_prop {
        width: 1250px;
      }

      &_no_prop {
        width: 1250px;
      }
    }
  }
}

@media screen and (min-width: 1101px) and (max-width: 1350px) {
  .create_task_modal_wrap {
    .raw {
      &_w_prop {
        width: 1050px;
      }

      &_no_prop {
        width: 1050px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  // @todo adjust width
  .create_task_modal_wrap {
    .raw {
      &_w_prop {
        width: 980px;
      }

      &_no_prop {
        width: 980px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  // mobile screen
  .create_task_modal_wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    border-bottom: 1px solid rgba($color: $app_white_shade3, $alpha: 0.2);
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;

    &_ios {
      height: 100%;
    }

    .raw {
      position: relative;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      border: none;
      margin: 0;
      box-shadow: none;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      border-radius: 0;

      &_default {
        height: 100%;
        width: 100%;
      }

      &_w_prop {
        height: 100%;
        width: 100%;
      }

      &_expand {
        height: 100%;
        width: 100%;
      }

      &_no_prop {
        height: 100%;
        width: 100%;
      }

      .close {
        display: none;
        height: 0;
        overflow: hidden;
      }

      &_ios {
        padding-top: 44px;
        box-sizing: border-box;
      }
    }
  }
}
