@import '../../../../theme/commons.scss';
@import '../../../../theme/color.scss';
@import '../../../../theme/scroll.scss';
@import '../../../../theme/assets.scss';

.tags {
  position: relative;
  width: 100%;
  margin-top: 12px;

  .title {
    position: relative;
    width: 90px;
    box-sizing: border-box;
    margin-top: 5px;

    & > p {
      font-size: 82%;
      font-weight: 600;
      color: $app_gray_shade2;
    }

    &_dark {
      & > p,
      & > h5 {
        color: $app_darkmode_text1;
      }
    }
  }

  .spinner_wrap {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-top: 5px;
    text-align: center;

    .raw {
      position: relative;

      &_spin {
        position: relative;
        display: inline-block;
        height: 25px;
        width: 25px;
      }
    }
  }

  .selected_list {
    position: relative;
    width: 100%;
    margin-top: 4px;
    display: inline-block;
    max-height: 130px;
    overflow-y: scroll;

    @include custom_scroll;

    & > ul {
      position: relative;
      width: 100%;
      list-style-type: none;
      list-style: none;

      & > li {
        position: relative;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: 4px;
        float: left;
        padding: 2px 7px;
        padding-bottom: 4px;
        border-radius: 5px;
        max-width: 200px;
        background: $app_white_shade6;

        .remove_selected,
        & > h5 {
          position: relative;
          display: inline-block;
          vertical-align: middle;

          span {
            font-weight: 700;
            margin-left: 2px;
          }
        }

        & > h5:nth-child(1) {
          font-weight: 600;
          font-size: 75%;
          color: $app_black_shade1;
          // border: 2px solid red;
          box-sizing: border-box;
          width: calc(100% - 23px);
        }

        .remove_selected {
          cursor: pointer;
          height: 12px;
          width: 12px;
          box-sizing: border-box;
          margin-left: 10px;
          background: url($close_x_black);
          background-position: center;
          background-size: 70% 70%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &_drop {
    position: absolute;
    z-index: $high_front;
    width: 100%;
    top: 35px;
    left: 0;
    padding: 4px 0;
    padding-right: 2px;
    padding-left: 6px;
    border-radius: 5px;
    background: $app_white_absolute;
    border: 1.5px solid $app_white_shade3;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(235, 86, 86, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    transition: height 0.1s ease-out;
    box-sizing: border-box;

    .empty {
      position: relative;
      width: 100%;

      & > h5 {
        font-weight: 600;
        font-size: 80%;
        color: $app_gray_shade2;
      }
    }

    .list {
      position: relative;
      width: 100%;
      display: inline-block;
      overflow-y: scroll;
      max-height: 95px;

      @include custom_scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-moz-scrollbar {
        width: 5px;
      }

      & > ul {
        position: relative;
        list-style-type: none;
        list-style: none;
        width: 100%;
        display: inline-block;

        & > li {
          padding: 6px 4px;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;

          &:hover {
            background: $app_white_shade4;
          }

          & > h5 {
            font-weight: 600;
            font-size: 80%;

            span {
              font-weight: 700;
              margin-left: 2px;
            }
          }
        }
      }
    }

    &_hide {
      visibility: hidden;
      height: 0;
      display: none;
      border: none;
    }
  }

  &_input {
    position: relative;
    width: 100%;
    display: inline-block;
    background: $app_white_absolute;
    border-radius: 5px;
    min-height: 30px;
    border: 1.5px solid $app_white_shade3;
    margin-top: 5px;
    padding: 0 5px;
    padding-top: 2px;
    box-sizing: border-box;

    &_w_selected {
      padding-bottom: 2px;
    }

    &_raw {
      position: relative;
      width: 100%;
      height: 30px;
      display: block;
      box-shadow: none;
      background: transparent;
      border: none;
      box-sizing: border-box;
      padding: 2px 5px;
      outline: none;
      font-size: 80%;
      font-weight: 500;

      &:focus,
      &:active {
        outline: none;
        border: none;
      }

      &::before,
      &::after {
        color: $app_darkmode_text1;
      }

      &_dark {
        background: $app_darkmode_background3;
        color: $app_darkmode_text1;
      }
    }

    &_dark {
      border: 1px solid $app_darkmode_border1;
      background: $app_darkmode_background3;
    }
  }
}
