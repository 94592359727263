@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';
@import '../../not-found/NotFoundAnnex.scss';

.not_found {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: $app_white_absolute;
  box-sizing: border-box;
  z-index: $high_front;

  &_wrap {
    position: relative;
    width: 100%;

    .app_logo {
      position: relative;
      display: inline-block;
      margin-bottom: 50px;
    }

    .zzz {
      position: relative;
      width: 320px;
      height: 40px;

      & > h1 {
        position: absolute;
        right: 0;
        font-weight: 700;
        font-size: 200%;
      }

      & > h1:nth-child(2) {
        right: 30px;
        bottom: 5px;
      }

      & > h1:nth-child(1) {
        bottom: 10px;
      }

      & > h1:nth-child(3) {
        right: 60px;
      }
    }

    .logo {
      position: relative;
      width: 310px;
      height: 180px;
      background: url($sleep_bear_two_black_border);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      & > div:nth-child(1) {
        position: absolute;
        top: -40px;
        left: 50px;
        height: 80px;
        width: 120px;
        background: url($sleep_bear_two_ontop_black_border);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    .msg {
      position: relative;
      display: block;
      width: 320px;
      text-align: center;
      margin-top: 20px;

      & > h2 {
        text-align: center;
        font-weight: 800;
        display: inline-block;
      }

      & > h4 {
        display: inline-block;
        font-weight: 600;
        color: $app_gray_shade2;
        margin-top: 10px;

        a {
          font-weight: 700;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &_dark {
        & > h4 {
          & > p {
            color: rgba($color: $app_darkmode_text1, $alpha: 0.8);
            font-weight: 500;

            a,
            span {
              color: $app_darkmode_text1;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &_dark {
    background: $app_darkmode_background1;
  }
}

@media screen and (min-width: $max_width_fix_task_create) {
  .not_found {
    &_wrap {
      width: $max_width_fix_task_create;
      margin-left: calc((100% - #{$max_width_fix_task_create}) / 2);
    }
  }
}

@media screen and (max-width: 380px) {
  .not_found {
    &_wrap {
      .zzz,
      .logo,
      .msg {
        width: calc(92% - 10px);
      }
    }
  }
}
