@import '../../../../../theme/color.scss';
@import '../../../../../theme/commons.scss';

@mixin ft_common {
  position: relative;
  height: 25px;
  width: 26px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;

  &:hover {
    .tip {
      display: block;
    }
  }
}

.tip {
  position: absolute;
  top: unset;
  bottom: 102%;
  left: 25%;
  white-space: nowrap;
  display: none;
}

.ft_inactive {
  & > button {
    color: $app_white_shade2;

    & > p {
      color: $app_white_shade2;

      span {
        color: $app_white_shade2;
      }
    }
  }

  &_dark {
    & > button {
      color: rgba($color: $app_darkmode_text1, $alpha: 0.4);

      & > p {
        color: rgba($color: $app_darkmode_text1, $alpha: 0.4);

        span {
          color: rgba($color: $app_darkmode_text1, $alpha: 0.4);
        }
      }
    }
  }

  &:hover {
    & > button {
      color: $app_darkmode_text1 !important;

      & > p {
        color: $app_darkmode_text1 !important;

        span {
          color: $app_darkmode_text1 !important;
        }
      }
    }
  }
}

.ft_active {
  & > button {
    color: $app_black_shade1;

    & > p {
      color: $app_black_shade1;

      span {
        color: $app_black_shade1;
      }
    }
  }

  &_focus {
    // background: $app_gray_shade1;
    background: rgba($color: $app_darkmode_text1, $alpha: 0.25) !important;
  }

  &_nofocus {
    // background: rgba($color: $app_gray_shade1, $alpha: 0.5);
    background: rgba($color: $app_darkmode_text1, $alpha: 0.25) !important;
  }

  &_dark {
    & > button {
      color: $app_darkmode_text1 !important;

      & > p {
        color: $app_darkmode_text1 !important;

        span {
          color: $app_darkmode_text1 !important;
        }
      }
    }
  }
}

.ft_h2_block {
  @include ft_common();

  button {
    position: relative;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    width: 25px;
    text-align: center;
    line-height: 26px;

    & > p {
      display: inline-block;

      span {
        font-weight: 700;
        display: inline-block;
      }

      & > span:nth-child(2) {
        font-size: 9px;
        margin-left: 2px;
      }
    }
  }
}

.ft_code_block {
  @include ft_common();

  button {
    position: relative;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    text-align: center;
    line-height: 25px;

    & > p {
      display: inline-block;
      padding-left: 1px;

      span {
        font-weight: 700;
        display: inline-block;
      }
    }
  }
}

.ft_justify {
  @include ft_common();
  margin-left: 8px;

  .selected {
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    button {
      position: relative;
      border: none;
      height: 100%;
      width: 100%;
      padding-top: 5px;
      box-sizing: border-box;
      background: transparent;
      outline: none;
      cursor: pointer;
      border-radius: 4px;

      svg {
        fill: $app_white_shade2;
      }

      &:active {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.25) !important;
      }
    }

    &_tip {
      position: absolute;
      top: unset;
      bottom: 102%;
      left: 25%;
      white-space: nowrap;
      display: none;
    }

    &:hover {
      .selected_tip {
        display: block;
      }
    }
  }

  &_active {
    .selected {
      button {
        svg {
          fill: $app_black_shade1;
        }
      }
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  & > div:nth-child(2) {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: calc(100% + 10px);
    left: -4px;
    background: $app_white_absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
    border: 1.5px solid $app_white_shade3;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;

    button {
      position: relative;
      border: none;
      outline: none;
      background: transparent;
      margin-top: 2px;
      height: 28px;
      width: 28px;
      cursor: pointer;

      svg {
        fill: rgba($color: $app_black_shade2, $alpha: 0.5);
      }

      &:hover {
        svg {
          fill: $app_black_shade1;
        }
      }
    }
  }

  &_dark {
    // inactive

    .selected {
      box-sizing: border-box;

      button {
        box-sizing: border-box;

        svg {
          fill: rgba($color: $app_darkmode_text1, $alpha: 0.4) !important;
        }
      }
    }

    &_active {
      .selected {
        button {
          svg {
            fill: $app_darkmode_text1;
          }
        }
      }
    }

    &_drop {
      & > div:nth-child(2) {
        background: $app_darkmode_background_header1;
        border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
        -moz-box-shadow: 0px 4px 50px $app_black_shade5;
        -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
        box-shadow: 0px 4px 50px $app_black_shade5;
        border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);

        button {
          svg {
            fill: rgba($color: $app_darkmode_text1, $alpha: 0.2);
          }

          &:hover {
            svg {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }
  }
}

.ft_more_selected {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

@media screen and (min-width: 901px) {
  .ft_more_selected {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .tip {
    display: none;
  }

  .ft_h2_block,
  .ft_code_block {
    &:hover {
      .tip {
        display: none;
      }
    }
    & > button {
      color: $app_black_shade1;

      & > p {
        color: $app_black_shade1;

        span {
          color: $app_black_shade1;
        }
      }
    }
  }

  .ft_active,
  .ft_inactive {
    &_dark {
      & > button {
        color: $app_darkmode_text1 !important;

        & > p {
          color: $app_darkmode_text1 !important;

          span {
            color: $app_darkmode_text1 !important;
          }
        }
      }
    }
  }

  .ft_justify {
    .selected {
      button {
        svg {
          fill: $app_black_shade1;
        }
      }

      &_tip {
        display: none;
        overflow: hidden;
      }

      &:hover {
        .selected_tip {
          display: none;
        }
      }
    }

    & > div:nth-child(2) {
      button {
        svg {
          fill: $app_black_shade1;
        }

        &:hover {
          svg {
            fill: $app_black_shade1;
          }
        }

        &:active {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.25
          ) !important;
        }
      }
    }

    &_dark {
      .selected {
        button {
          svg {
            fill: $app_darkmode_text1 !important;
          }
        }
      }

      &_active {
        .selected {
          button {
            svg {
              fill: $app_darkmode_text1;
            }
          }
        }
      }

      &_drop {
        & > div:nth-child(2) {
          button {
            svg {
              fill: $app_darkmode_text1;
            }

            &:hover {
              svg {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }

    & > div:nth-child(2) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      top: -2px;
      right: 50%;
      left: unset;
      z-index: calc(#{$high_front} + 1);
      padding: 2px 10px;
    }
  }
}
