@import '../../../theme/commons.scss';
@import '../../../theme/assets.scss';

.better_image {
  max-height: 480px;
  width: 100%;
  padding: 0 20px;
  object-position: center;
  display: block;
  cursor: zoom-in;

  &_wrap {
    position: relative;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    box-sizing: border-box;

    &::before {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: ' ';
      box-sizing: border-box;
      border-radius: 5px;
    }

    & > span {
      & > img {
        position: relative;
        max-width: 100%;
        padding: 0 2px;
        object-fit: contain;
        object-position: center;
        box-sizing: border-box;
        -webkit-user-drag: none;
      }
    }
  }
}

.uploading_image {
  position: relative;
  opacity: 0.3;
}

@media screen and (max-width: 900px) {
  .better_image {
    width: 100%;
    margin: 0;
  }
}
