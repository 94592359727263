@import '../../../theme/commons.scss';
@import '../../../theme/color.scss';
@import '../../../theme/scroll.scss';

.marks_h2 {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 20px 0;
  margin-top: 5px;
  padding-left: 3px;
  box-sizing: border-box;
  scrollbar-color: #e8e8e8 transparent;
  // border: 1px solid red;

  @include custom_scroll;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &::-moz-scrollbar-thumb {
    visibility: hidden;
  }

  &:hover {
    scrollbar-color: rgba($color: #636363, $alpha: 0.4) transparent;
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }

    &::-moz-scrollbar-thumb {
      visibility: visible;
    }
  }

  &_dark {
    @include custom_scroll_dark;
  }

  & > ul {
    position: relative;
    width: 100%;
    list-style: none;
    list-style-type: none;
    box-sizing: border-box;

    .is_active {
      &::before {
        position: absolute;
        left: -4px;
        content: ' ';
        height: 50%;
        width: 4px;
        top: 25%;
        border-radius: 7px;
        background: $app_dominant_purple;
      }
    }

    & > li {
      position: relative;
      padding: 2px 0;
      width: 100%;
      cursor: pointer;

      &::before {
        position: absolute;
        left: -4px;
        content: ' ';
        height: 50%;
        width: 4px;
        top: 25%;
        border-radius: 7px;
        background: $app_white_shade7;
      }

      & > h5 {
        font-size: 80%;
        font-weight: 600;
        position: relative;
        padding-left: 8px;
        line-height: 25px;
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & > li:not(:nth-child(1)) {
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 900px) {
  .marks_h2 {
    display: none;
  }
}
