@import '../../../../theme/commons.scss';
@import '../../../../theme/color.scss';

.activity_item {
  position: relative;
  width: 100%;
  // border: 1px solid red;
  box-sizing: border-box;

  p {
    position: relative;
    font-weight: 600;
    font-size: 85%;
    display: inline-block;
    vertical-align: middle;

    span {
      font-weight: 700;
      font-size: 100%;
    }
  }

  .update_title,
  .update_desc,
  .created_code,
  .state_change,
  .exp_change {
    position: relative;
  }
}
