@import '../../../../theme/color.scss';
@import '../../../../theme/commons.scss';

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video_placeholder {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 4px 8px;
  box-sizing: border-box;
  overflow: hidden;

  & > div {
    position: relative;
    height: 240px;
    width: 450px;
    border-radius: 4px;
    background: black;
    margin: 0;
    border: 1px solid transparent;
    box-sizing: border-box;

    &:active {
      border: 1px solid white;
    }
  }

  &_play {
    position: relative;
    box-sizing: border-box;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top: 4px solid rgba($color: $app_dominant_purple, $alpha: 1);
    -webkit-animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite; /* Safari */
    -moz-animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
}

@media screen and (min-width: 1200px) {
  .video_placeholder {
    & > div {
      height: 260px;
      width: 530px;
    }
  }
}

@media screen and (max-width: 460px) {
  .video_placeholder {
    position: relative;
    padding: 4px;

    & > div {
      position: relative;
      height: 125px;
      width: 92%;
    }
  }
}
