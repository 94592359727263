@import '../../../../theme/commons.scss';
@import '../../../../theme/color.scss';
@import '../../../../theme/scroll.scss';
@import '../../../../theme/assets.scss';

.task_subs {
  position: relative;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;

  .title {
    position: relative;
    width: 90px;
    box-sizing: border-box;
    margin-top: 5px;

    & > p {
      font-size: 80%;
      font-weight: 600;
      color: $app_gray_shade2;
    }

    &_dark {
      & > p,
      & > h5 {
        color: $app_darkmode_text1;
      }
    }
  }

  .spinner_wrap {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-top: 5px;
    text-align: center;

    .raw {
      position: relative;
      display: inline-block;
      text-align: center;
      margin-top: 5px;
    }
  }

  .subs_preview,
  .subs_list {
    position: relative;
    width: 100%;

    & > ul {
      position: relative;
      width: 100%;
      list-style: none;
      list-style-type: none;
      display: inline-block;

      & > li {
        position: relative;
        float: left;
        box-sizing: border-box;
      }
    }
  }

  .subs_preview {
    // subscribers preview
    padding-left: 5px;
    box-sizing: border-box;
    margin-top: 5px;

    .others {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 60px;
    }

    & > ul {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 65px);
      margin-right: 5px;

      & > li:not(:nth-child(1)) {
        margin-left: -3px;
      }

      & > li {
        vertical-align: middle;
        display: inline-block;
        position: relative;
        height: 25px;
        width: 25px;

        .subs_preview_avatar {
          position: relative;
          height: 25px;
          width: 25px;

          h5 {
            line-height: 26px;
            font-size: 65%;
            font-weight: 500;
          }
        }
      }
    }
  }

  .subs_list {
    // subscribers list
    box-sizing: border-box;
    margin: 5px 0;
    overflow-y: scroll;
    max-height: 220px;

    @include custom_scroll;

    & > ul {
      & > li {
        padding: 2px 8px;
        border-radius: 5px;
        display: inline-block;
        background: $app_white_shade6;
        max-width: 100%;
        margin-right: 5px;
        margin-top: 4px;

        .remove_selected {
          position: relative;
          cursor: pointer;
          height: 12px;
          width: 12px;
          box-sizing: border-box;
          margin-left: 8px;
          background: url($close_x_black);
          background-position: center;
          background-size: 70% 70%;
          background-repeat: no-repeat;
          display: inline-block;
          vertical-align: middle;
        }

        .avatar,
        & > h5 {
          display: inline-block;
          vertical-align: middle;
        }

        .avatar {
          position: relative;
          height: 22px;
          width: 22px;

          & > div {
            height: 22px;
            width: 22px;

            & > div {
              h5 {
                line-height: 17px;
                font-size: 60%;
                margin-bottom: 2px;
                font-weight: 500;
              }
            }
          }
        }

        & > h5 {
          position: relative;
          line-height: 25px;
          margin-left: 10px;
          font-weight: 600;
          font-size: 73%;
          max-width: calc(100% - 40px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }

  .subs_input {
    position: relative;
    width: 100%;
    display: inline-block;
    background: $app_white_absolute;
    border-radius: 5px;
    min-height: 30px;
    border: 1.5px solid $app_white_shade3;
    margin-top: 5px;
    padding: 0 5px;
    padding-top: 2px;
    box-sizing: border-box;

    &_w_selected {
      padding-bottom: 6px;
    }

    &_raw {
      position: relative;
      width: 100%;
      height: 30px;
      display: block;
      box-shadow: none;
      background: transparent;
      border: none;
      box-sizing: border-box;
      padding: 2px 5px;
      outline: none;
      font-size: 80%;
      font-weight: 500;

      &:focus,
      &:active {
        outline: none;
        border: none;
      }
    }
  }

  &_drop {
    position: absolute;
    z-index: $high_front;
    width: 100%;
    left: 0;
    top: 35px;
    padding: 5px 0;
    padding-right: 3px;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    background: $app_white_absolute;
    border: 1.5px solid $app_white_shade3;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    transition: height 0.1s ease-out;

    .empty {
      position: relative;
      width: 100%;

      & > h5 {
        font-weight: 600;
        font-size: 80%;
        color: $app_gray_shade2;
      }
    }

    .list {
      position: relative;
      width: 100%;
      display: inline-block;
      overflow-y: scroll;
      max-height: 170px;
      padding: 0;
      box-sizing: border-box;

      @include custom_scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-moz-scrollbar {
        width: 5px;
      }

      &_item_avatar {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: 30px;
        width: 30px;

        & > div {
          height: 30px;
          width: 30px;
          box-sizing: border-box;

          & > div {
            h5 {
              line-height: 30px;
              box-sizing: border-box;
            }
          }
        }
      }

      & > ul {
        position: relative;
        list-style-type: none;
        list-style: none;
        width: 100%;
        padding: 0;

        & > li {
          padding: 4px;
          position: relative;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;

          &:hover {
            background: $app_white_shade4;
          }

          & > h5 {
            font-weight: 600;
            font-size: 75%;
            display: inline-block;
            vertical-align: middle;
            line-height: 25px;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 42px);
          }

          .unsave_user {
            position: absolute;
            right: 2px;
            top: 4px;
            padding: 0 4px;
            background: transparent;

            &:hover {
              background: $app_white_light2;
            }

            svg {
              height: 14px;
              width: 14px;
            }

            &_dark {
              &:hover {
                background: $app_white_light2;
              }

              svg {
                fill: $app_darkmode_text1;

                path: {
                  fill: $app_darkmode_text1;
                }
              }
            }
          }
        }
      }
    }

    &_hide {
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
      overflow: hidden;
      border: none;
    }
  }
}
